import React, { useReducer, useState, useEffect } from 'react';

import Loader from '../../../../components/Loader/Loader';
import UserChip from '../../../../components/UserChip/UserChip';

import { DropdownButton } from '../../../../atoms/DropdownButton/DropdownButton';
import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';

import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';

import './PicksSettings.css';


function reducer(state, action) {
    console.log(`reducer (${action.type}, ${action?.payload})`);   
    
    let newState = structuredClone(state);
    try {  
        let i = action?.payload?.index;

        switch(action.type) {
            case 'initialize':
                newState = {
                    form: action.payload.form,
                    submission: action.payload.submission
                }
                return newState;
  
            case 'set_form':
                newState.form = action.payload;
                return newState;

            case 'set_submission':
                newState.submission = action.payload;
                return newState;
  
            case 'update_property':
                if(action.payload.property === 'Form State') {
                    newState.form.state = action.payload.value;
                    
                } else if(action.payload.property === 'Submission State') {
                    newState.submission.state = action.payload.value;
                    
                } else if(action.payload.property === 'Submission Deadline') {
                    newState.form.submissionDeadline = `${action.payload.value}:00Z`;
                    newState.form.submissionDeadlineDate = new Date(newState.form.submissionDeadline);
                    newState.form.submissionDeadlineString = newState.form.submissionDeadlineDate.toISOString().slice(0, 16); // YYYY-MM-DDTHH:DD
                    console.log('submissionDeadlineString', newState.form.submissionDeadlineString)
                    newState.form.submissionDeadlineLocalString = `${newState.form.submissionDeadlineDate.toLocaleString([], {weekday: 'short' })}, ${newState.form.submissionDeadlineDate.toLocaleString([], {month: 'short', day: 'numeric' })}, ${newState.form.submissionDeadlineDate.toLocaleString([], {hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}`;
        
                    // newState.submission.state = action.payload.value;
                    // date: date,
                    // datetime: new Date(`${date}:00Z`),
                    // datetimestring: date

                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                }
                return newState;
  

  
            case 'update_submission_property':
                if(action.payload.property === 'Submission State') {
                    newState.submission[i].state = action.payload.value;

                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                }
                return newState;

            case 'delete_submission':
                if(newState.submission[i].action === 'add') {
                    newState.splice(i, 1);
                } else {
                    newState.submission[i].action = 'delete';
                }
                return newState;

            case 'restore_submission':
                delete newState.submission[i].action;
                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}


function PicksSettings({config, onHide}) {
    const [state, dispatch] = useReducer(reducer, []);
    const [dbFormData, setDbFormData] = useState();
    const [dbSubmissionData, setDbSubmissionData] = useState();
    const [hasSaved, setHasSaved] = useState(false);
    
    const [loader, setLoader] = useState({show: false, text: ''});
    
    useEffect(() => {
        if(config.show) {
            console.log('PicksSettings', config);

            fetchFormInfo();
            fetchSubmissions();
        }
    }, [config]);

    const fetchFormInfo = () => {
        setLoader({show: true, text: 'Fetching Form Settings...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                formId: config.form.formId
            })
        };

        fetch(apiUrl + "/app/form", requestOptions)
            .then(response => {
                if(response.status === 200) {
                    return response.json()
                }
            })
            .then(data => {
                data.submissionDeadlineDate = new Date(data.submissionDeadline);
                data.submissionDeadlineString = data.submissionDeadlineDate.toISOString().slice(0, 16); // YYYY-MM-DDTHH:DD
                data.submissionDeadlineLocalString = `${data.submissionDeadlineDate.toLocaleString([], {weekday: 'short' })}, ${data.submissionDeadlineDate.toLocaleString([], {month: 'short', day: 'numeric' })}, ${data.submissionDeadlineDate.toLocaleString([], {hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}`;

                dispatch({type: 'set_form', payload: data});
                setDbFormData(data);
                setLoader({show: false});            
            })
        .catch((err) => {
            console.log(err);
        });
    }

    const fetchSubmissions = () => {
        setLoader({show: true, text: 'Fetching Submissions...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                formId: config.form.formId
            })
        };

        fetch(apiUrl + "/app/submissions", requestOptions)
            .then(response => {
                if(response.status === 200) {
                    return response.json()
                }
            })
            .then(data => {
                dispatch({type: 'set_submission', payload: data});
                setDbSubmissionData(data);
                setLoader({show: false});            
            })
        .catch((err) => {
            console.log(err);
        });
    }

    const refreshDriverPicks = () => {
        setLoader({show: true, text: 'Refreshing Driver Picks...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                formId: config.formId
            })
        };
    
        fetch(apiUrl + "/app/form/refreshDriverPicks", requestOptions)
        .then(response => {
            setLoader({show: false});
            onHide('Update');
        })
        .catch((err) => console.log(err));
    }

    const calculateResults = () => {
        setLoader({show: true, text: 'Calculating results...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                formId: config.formId
            })
        };
    
        fetch(apiUrl + "/app/results/calculate", requestOptions)
        .then(response => {
            setLoader({show: false});
            onHide('Update');
        })
        .catch((err) => console.log(err));
    }


    const saveForm = () => {
        // console.log('saveForm', state.form);
        setLoader({show: true, text: 'Saving Form Settings...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(state.form)
        };

        fetch(apiUrl + "/app/form/save", requestOptions)
          .then(response => {
            if(response.status === 200) {
                fetchFormInfo();
                setHasSaved(true);
            }
            setLoader({show: false});
          })
        .catch((err) => {
            console.log(err);
        });
    }

    const saveSubmission = () => {
        // console.log('saveSubmission', state.submission);
        setLoader({show: true, text: 'Saving Submission Settings...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                submission: state.submission
            })
        };

        fetch(apiUrl + "/app/submission/save", requestOptions)
          .then(response => {
            if(response.status === 200) {
                fetchSubmissions();
                setHasSaved(true);
            }
            setLoader({show: false});
          })
        .catch((err) => {
            console.log(err);
        });
    }

    const handlePropertyChange = (property, value) => {
        console.log(`handlePropertyChange [${property}] => ${value}`);
        
        dispatch({type: 'update_property', payload: {property, value}});
    }

    const handleSubmissionPropertyChange = (index, property, value) => {
        console.log(`handleSubmissionPropertyChange [index: ${index}] [${property}] => ${value}`);
        
        dispatch({type: 'update_submission_property', payload: {index, property, value}});
    }
    const deleteSubmission = (_, index) => {
        dispatch({type: 'delete_submission', payload: {index}});
    }

    const restoreSubmission = (_, index) => {
        dispatch({type: 'restore_submission', payload: {index}});
    }

    const handleCloseEvent = () => {
        console.log(`handleCloseEvent`);
        if(hasSaved) {
            onHide('Reload');
        } else {
            onHide();
        }
        setHasSaved(false);
    }

    const isFormDirty = () => {
        return JSON.stringify(state.form) !== JSON.stringify(dbFormData)
    }

    const isSubmissionDirty = () => {
        return JSON.stringify(state.submission) !== JSON.stringify(dbSubmissionData)
    }
    
    return (
        <Modal show={config.show} onHide={handleCloseEvent} closeOnOverlayClick={true} className='full-width'>
            <Loader config={loader}></Loader>
            
            <h2>Picks Settings</h2>

            <div className='flex flex-column gap-1'>
                <div>
                    <div className="divider"></div>
                    <div className="flex justify-content-between">
                        <h3 className="margin-0">Form</h3>
                        <Button variant="primary" onClick={saveForm} disabled={!isFormDirty()}>Save <SaveIcon fontSize='small' /></Button>
                    </div>
                    <div className="label-adorner">{`{ formId: ${state.form?.formId}, state: ${dbFormData?.state} }`}</div>
                </div>

                <div className='flex flex-column gap-1'>

                    <div className="flex justify-content-between align-items-center">
                        <h4>State:</h4>
                        <div className="btn-group">
                            <Button 
                                variant={state.form?.state==='Draft' ? 'primary' : 'outline-light'} 
                                onClick={() => handlePropertyChange('Form State','Draft')}>Draft</Button>

                            <Button 
                                variant={state.form?.state==='Live' ? 'primary' : 'outline-light'} 
                                onClick={() => handlePropertyChange('Form State','Live')}>Live</Button>

                            <Button 
                                variant={state.form?.state==='Complete' ? 'primary' : 'outline-light'} 
                                onClick={() => handlePropertyChange('Form State','Complete')}>Complete</Button>
                        </div>
                    </div>
                    <p className='sidenote'>
                        {state.form?.state==='Draft' && (
                        <p>People will no longer be able to see the form. If they had already submitted picks, their submissions are still saved but they will no longer be able to see their submission.</p>
                        )}

                        {state.form?.state==='Live' && (<>
                        <p>People will be able to fill in this form and submit their picks if it is the next event in the schedule.</p>
                        
                        </>)}

                        {state.form?.state==='Complete' && (<>
                        <p>The banner notifying users that the results are pending will be removed and they will be able to view the full results.</p>
                        </>)}
                    </p>

                    <div>
                        <p><b>Submission Deadline:</b></p>
                        <div className='flex'>
                            <input 
                                type="datetime-local" 
                                value={state.form?.submissionDeadlineString} 
                                onChange={(e) => handlePropertyChange('Submission Deadline', e.target.value)} 
                                name="Date" />
                        </div>                    
                    </div>
                    <p className='sidenote'>Local: {state.form?.submissionDeadlineLocalString}</p>

                    {/* <div className="flex justify-content-between">
                        <p><b>Calculate Results:</b></p>
                        <div>
                            <Button variant='primary' onClick={refreshDriverPicks}>Refresh Driver Picks</Button>
                        </div>
                    </div> */}

                    <div className="flex justify-content-between">
                        <p><b>Calculate Results:</b></p>
                        <div>
                            <Button variant='primary' onClick={calculateResults}>Calculate Results</Button>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="divider"></div>
                    <div className="flex justify-content-between">
                        <h3 className="margin-0">Submissions</h3>
                        <Button variant="primary" onClick={saveSubmission} disabled={!isSubmissionDirty()}>Save <SaveIcon fontSize='small' /></Button>
                    </div>
                </div>

                <div style={{overflowX: 'auto'}}>
                    <table className='table schedule-settings-table'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th></th>
                                <th>State</th>
                                <th>Points</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                        { state.submission?.length > 0 && (
                            state.submission?.map((s, i) => (
                            <tr style={s.action==='delete' ? {textDecoration: 'line-through'} : (s.action==='add' ? {color: 'dodgerblue'} : {})}>
                                <td>{s.submissionId}</td>
                                <td>
                                    <UserChip user={s.user}></UserChip>
                                </td>
                                <td>
                                    <DropdownButton 
                                        options={['Draft', 'Submitted']}
                                        value={s.state}
                                        clickFunction={(value) => handleSubmissionPropertyChange(i, 'Submission State', value)}
                                        >
                                    </DropdownButton>
                                </td>
                                <td>
                                    {s.totalPoints}
                                </td>
                                <td>
                                    {s.action !== 'delete' && (
                                    <Button variant="icon" onClick={(e) => deleteSubmission(e, i)}><DeleteIcon fontSize='small' /></Button>
                                    )}
                                    {s.action === 'delete' && (
                                    <Button variant="icon" onClick={(e) => restoreSubmission(e, i)}><ReplayIcon fontSize='small' /></Button>
                                    )}
                                </td>
                            
                            </tr>
                            ))
                        )}
                        </tbody>
                    </table>
                </div>

                {/* <div className="flex justify-content-between align-items-center">
                    <h4>State:</h4>
                    <div className="btn-group">
                        <Button 
                            variant={state.submission?.state==='Draft' ? 'primary' : 'outline-light'} 
                            onClick={() => handlePropertyChange('Submission State','Draft')}>Draft</Button>

                        <Button 
                            variant={state.submission?.state==='Submitted' ? 'primary' : 'outline-light'} 
                            onClick={() => handlePropertyChange('Submission State','Submitted')}>Submitted</Button>

                    </div>
                </div> */}
                <div className="divider"></div>
            </div>

            <div className="modal-footer">
                <Button variant="secondary" onClick={handleCloseEvent}>Close</Button>
            </div>

        </Modal>

     );
    
}

export default PicksSettings;