import React, { useState, useEffect } from 'react';
import Loader from '../../../../components/Loader/Loader';
import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';
import { DropdownButton } from '../../../../atoms/DropdownButton/DropdownButton';


import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import './PitStopSettings.css';

function PitStopSettings({config, onHide}) {
    const [message, setMessage] = useState('');
    const [drivers, setDrivers] = useState();
    const [pitStops, setPitStops] = useState([]);
    const [loader, setLoader] = useState({show: false, text: ''});
    const [statusOptions, ] = useState([null, 'DNF', 'DNS']);
    
    const [recentEventsList, setRecentEventsList] = useState(['']);
    const [selectedRecentEvent, setSelectedRecentEvent] = useState('');
    
    useEffect(() => {
        setMessage('');

        // setMeetingKey(config.meetingKey);
        // setSessionKey(config.sessionKey);
        if(config.show) {
            fetchRecentEvents();
            fetchDrivers(config.eventId).then(drivers => {console.log('drivers', drivers); setDrivers(drivers)});
            fetchPitStops(config.eventId).then(pitStops => {console.log('pit stops', pitStops); setPitStops(pitStops)});
            
            console.log('config', config);
        }

    }, [config]);

    

    const fetchRecentEvents = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
          })
      };
    
      setLoader({show: true, text: 'Loading Recent Events...'});
      fetch(apiUrl + `/app/event/recent`, requestOptions)
        .then(response => response.json())
        .then(data => {
            let updatedList = data.map(event => ({
                ...event, 
                shorthand: `${event.name} (${event.type})(${event.nDrivers})`
            }));

            setRecentEventsList(updatedList);
            setLoader({show: false});
        })
        .catch((err) => {
            console.log(err);
            setLoader({show: false});
        });
    }

    const fetchDrivers = async (eventId) => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: eventId
            })
        };
        
        setLoader({show: true, text: 'Loading Event Drivers...'});
        return await fetch(apiUrl + `/app/event/sessionDrivers`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setLoader({show: false});
                return data;
            })
            .catch((err) => {
                console.log(err);
                setLoader({show: false});
            });
    }

    const fetchPitStops = async (eventId) => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: eventId
            })
        };
        
        setLoader({show: true, text: 'Loading Pit Stops...'});
        return await fetch(apiUrl + `/app/event/sessionPitStops`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setLoader({show: false});
                return data;
            })
            .catch((err) => {
                console.log(err);
                setLoader({show: false});
            });
    }
    
    
    const matchRecent = async () => {
        setMessage('');

        let recentEvent = recentEventsList.find(event => event.shorthand===selectedRecentEvent)

        let importedDrivers = await fetchDrivers(recentEvent.id);

        if(importedDrivers === undefined) {
            setMessage('No matching drivers found from recent.');

        } else {
            setMessage(`Found ${importedDrivers.length} drivers from recent.`);

            let updatedDrivers = compareAndUpdateDrivers(importedDrivers);

            console.log('importedDrivers', importedDrivers);
            console.log('updatedDrivers', updatedDrivers);

            setDrivers(updatedDrivers); 
        }
    }


    const matchOpenF1 = async () => {
        setMessage('');

        let importedDrivers = await fetchOpenF1Drivers(config.sessionKey);

        if(importedDrivers === undefined) {
            setMessage('No matching drivers found.');

        } else {
            setMessage(`Found ${importedDrivers.length} drivers.`);

            let updatedDrivers = compareAndUpdateDrivers(importedDrivers);

            console.log('importedDrivers', importedDrivers);
            console.log('updatedDrivers', updatedDrivers);

            setDrivers(updatedDrivers); 
        }


        let importedPitStops = await fetchOpenF1PitStops(config.sessionKey);

        if(importedPitStops === undefined) {
            setMessage(`${message} No matching pit stops found.`);

        } else {
            setMessage(`${message} Found ${importedPitStops.length} pit stops.`);

            let updatedPitStops = compareAndUpdatePitStops(importedPitStops);

            console.log('importedPitStops', importedPitStops);
            console.log('updatedPitStops', updatedPitStops);

            setPitStops(updatedPitStops); 
        }
    }


    const fetchOpenF1Drivers = async (sessionKey) => {
        return await fetch(`https://api.openf1.org/v1/drivers?session_key=${sessionKey}`)
            .then(response => response.json())
            .then(data => {
                let refactored = data.map(driver => {
                    
                    if (driver.last_name === 'Perez') {driver.last_name = 'Pérez'}
                    else if (driver.last_name === 'Hulkenberg') {driver.last_name = 'Hülkenberg'}
                    
                    if (driver.team_name === 'Red Bull Racing') {driver.team_name = 'Red Bull'}
                    else if (driver.team_name === 'RB') {driver.team_name = 'Visa Cash App RB'}
                    else if (driver.team_name === 'Haas F1 Team') {driver.team_name = 'Haas'}

                    return {
                        firstName: driver.first_name,
                        lastName: driver.last_name,
                        constructorName: driver.team_name,
                        driverNumber: driver.driver_number
                    }})
                return refactored
            })
            .catch((err) => {
                console.log(err);
            });
    }


    const fetchOpenF1PitStops = async (sessionKey) => {
        return await fetch(`https://api.openf1.org/v1/pit?session_key=${sessionKey}`)
            .then(response => response.json())
            .then(data => {
                let refactored = data.map(pitStop => {
                    return {
                        driverNumber: pitStop.driver_number,
                        lap: pitStop.lap_number,
                        duration: pitStop.pit_duration
                    }})
                return refactored
            })
            .catch((err) => {
                console.log(err);
            });
    }
    
    const compareAndUpdateDrivers = (importedDrivers) => {
        console.log('drivers', drivers);
        // Create a copy of current sessions
        let updatedDrivers = [...drivers];
      
        // Iterate over imported sessions
        importedDrivers.forEach(importedDriver => {
            // Find matching driver in current drivers based on last name
            let match = updatedDrivers.find(driver => {
                if (driver.lastName === importedDriver.lastName) {return driver}
                else {return undefined}
            });
      
            if (match) {
                // If date or name is different, update the event and set action to "Update"
                if (match.driverNumber === importedDriver.driverNumber) {
                    match.action = 'Same';
                } else {
                    match.driverNumber = importedDriver.driverNumber;
                    match.action = 'Update';
                } 
            } else {
                // If no match is found, add the event to the list with action set to "Add"
                updatedDrivers.push({
                    firstName: importedDriver.firstName,
                    lastName: importedDriver.lastName,
                    constructorName: importedDriver.constructorName,
                    driverNumber: importedDriver.driverNumber,
                    action: 'Add'
                });
            }
        });
      
        return updatedDrivers;
    };
    
    const compareAndUpdatePitStops = (importedPitStops) => {
        console.log('pitStops', pitStops);
        // Create a copy of current sessions
        let updatedPitStops = [...pitStops];
      
        // Iterate over imported sessions
        importedPitStops.forEach(importedPitStop => {
            // Find matching driver in current drivers based on last name
            let match = updatedPitStops.find(pitStop => {
                if (pitStop.driverNumber === importedPitStop.driverNumber && pitStop.lap === importedPitStop.lap) {return pitStop}
                else {return undefined}
            });
      
            if (match) {
                // If date or name is different, update the event and set action to "Update"
                if (match.duration === importedPitStop.duration) {
                    match.action = 'Same';
                } else {
                    match.duration = importedPitStop.duration;
                    match.action = 'Update';
                } 
            } else {
                // If no match is found, add the event to the list with action set to "Add"
                updatedPitStops.push({
                    lap: importedPitStop.lap,
                    duration: importedPitStop.duration,
                    constructor: {},
                    driver: {driverNumber: importedPitStop.driverNumber},
                    action: 'Add'
                });
            }
        });
      
        return updatedPitStops;
    };

    const handleCloseEvent = () => {
        setDrivers();
        onHide();
    }

    const updateSessionDriver = async (e, driver) => {
        e.preventDefault();

        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId,
                firstName: driver.firstName,
                lastName: driver.lastName,
                startPosition: driver.startPosition,
                endPosition: driver.endPosition,
                lapsCompleted: driver.lapsCompleted,
                status: driver.status,
                fastestLap: driver.fastestLap,
                dotd: driver.dotd,
                driverNumber: driver.driverNumber,
                constructorName: driver.constructorName
            })
        };
        try {
            return fetch(apiUrl + "/app/event/updateSessionDriver", requestOptions);
        }
        catch (err) {
            console.log(err);
        };
    }

    const updateAllSessionDrivers = async (e) => {
        e.preventDefault();
        
        const promises = [];

        drivers.forEach(driver => {
            promises.push(updateSessionDriver(e, driver));
        });

        await Promise.all(promises);
        await calculateEventGrid();
        
        fetchDrivers(config.eventId).then(fetchedDrivers => setDrivers(fetchedDrivers));
    }


    const updateSessionPitStop = async (e, pitStop) => {
        e.preventDefault();

        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId,
                driverNumber: pitStop.driver.driverNumber,
                lap: pitStop.lap,
                duration: pitStop.duration
            })
        };
        try {
            return fetch(apiUrl + "/app/event/updateSessionPitStop", requestOptions);
        }
        catch (err) {
            console.log(err);
        };
    }

    const updateAllSessionPitStops = async (e) => {
        e.preventDefault();
        
        const promises = [];

        pitStops.forEach(pitStop => {
            promises.push(updateSessionPitStop(e, pitStop));
        });

        await Promise.all(promises);
        await calculateEventGrid();
        
        fetchPitStops(config.eventId).then(pitStops => {console.log('pit stops', pitStops); setPitStops(pitStops)});
    }

    const calculateEventGrid = async () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId
            })
        };
        try {
            return fetch(apiUrl + "/app/event/calculateEventGrid", requestOptions);
        }
        catch (err) {
            console.log(err);
        };
    }
    
    const formatDuration = (duration) => {        
        let minutes = Math.floor(duration / 60);
        let seconds = (duration % 60).toFixed(3); // Keep the thousandth place

        if (minutes === 0) {
            return `${seconds}`;

        } else {
            let [real, decimal] = seconds.split('.');
            real = real.padStart(2, '0'); // Ensure the real part is two digits
            seconds = `${real}.${decimal}`;
            return `${minutes}:${seconds}`;
        }
    }

    const importOpenF1DriverData = (e) => {
        e.preventDefault();

        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId,
                sessionKey: config.sessionKey
            })
        };

        fetch(apiUrl + "/import/lapData", requestOptions)
            .then(data => {
                fetchDrivers(config.eventId).then(fetchedDrivers => setDrivers(fetchedDrivers));
                console.log(data);
            })
        .catch((err) => {
            console.log(err);
        });

        fetch(apiUrl + "/import/positionData", requestOptions)
            .then(data => {
                fetchDrivers(config.eventId).then(fetchedDrivers => setDrivers(fetchedDrivers));
                console.log(data);
            })
        .catch((err) => {
            console.log(err);
        });
    }


    
    const handleEventDriversChange = (event, driverId) => {
        //deep object copy is needed otherwise UseEffect wont fire
        let updatedDrivers = JSON.parse(JSON.stringify(drivers)); 
        console.log(`handleEventDriversChange [${event.target.name}] [${driverId}]`);

        for (const [i, d] of Object.entries(updatedDrivers)) {
            if (d.driverId === driverId) {
                if (event.target.name === 'Start Position') {
                    updatedDrivers[i].startPosition = event.target.value === '' ? null : event.target.value;
                    console.log(`Updated ${event.target.name} [${d.driverId}] to ${event.target.value}`);
                }
                if (event.target.name === 'End Position') {
                    updatedDrivers[i].endPosition = event.target.value === '' ? null : event.target.value;
                    console.log(`Updated ${event.target.name} [${d.driverId}] to ${event.target.value}`);
                }
                if (event.target.name === 'Laps') {
                    updatedDrivers[i].lapsCompleted = event.target.value === '' ? null : event.target.value;
                    console.log(`Updated ${event.target.name} [${d.driverId}] to ${event.target.value}`);
                }
                if (event.target.name === 'Status') {
                    updatedDrivers[i].status = event.target.value === '' ? null : event.target.value;
                    console.log(`Updated ${event.target.name} [${d.driverId}] to ${event.target.value}`);
                }
            }
        }

        setDrivers(updatedDrivers);
    }

    const handleEventDriversStatusChange = (status, driverId) => {
        //deep object copy is needed otherwise UseEffect wont fire
        let updatedDrivers = JSON.parse(JSON.stringify(drivers)); 
        console.log(`handleEventDriversStatusChange [${driverId}] [${status}]`);
        
        for (const [i, d] of Object.entries(updatedDrivers)) {
            if (d.driverId === driverId) {
                updatedDrivers[i].status = status;
                console.log(`Updated Status [${d.driverId}] to [${status}]`);
            }
        }
        setDrivers(updatedDrivers);
    }

    const handleEventDriverRadioButtonStatChange = (e, driverId) => {
        //deep object copy is needed otherwise UseEffect wont fire
        let updatedDrivers = structuredClone(drivers); 
        console.log(`handleEventDriverRadioButtonStatChange [${e.target.name}] [${driverId}]`);
        
        for (const [i, d] of Object.entries(updatedDrivers)) {
            if (d.driverId === driverId) {

                if (e.target.name==='Fastest Lap') {
                    // first remove if any other row had stat = true
                    updatedDrivers.forEach((row)=>row.fastestLap=false);

                    // then set the new row with stat
                    updatedDrivers[i].fastestLap = true;
                }

                if (e.target.name==='DotD') {
                    // first remove if any other row had stat = true
                    updatedDrivers.forEach((row)=>row.dotd=false);

                    // then set the new row with stat
                    updatedDrivers[i].dotd = true;
                }
                console.log(`Updated [${d.driverId}] to have [${e.target.name}]`);
            }
        }
        setDrivers(updatedDrivers);
    }

    
    // const [addPitStopModal, setAddPitStopModal] = useState({show: false});
    // const openAddPitStop = () => setAddPitStopModal({show: true, eventId: event.id});
    // const closeAddPitStop = (action) => {
    //   if (action==='Add') {
  
    //     let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;      
    //     const requestOptions = {
    //       method: 'POST',
    //       headers: { 'Content-Type': 'application/json' },
    //       body: JSON.stringify({                
    //         eventId: addPitStopModal.eventId,
    //         constructorId: addPitStopModal.constructorId,
    //         driverId: addPitStopModal.driverId,
    //         lap: addPitStopModal.lap,
    //         duration: addPitStopModal.duration,
    //       })
    //     }
  
    //     setLoader({show: true, text: `Adding pit stop...`});
    //     fetch(apiUrl + `/app/eventPitStops/add`, requestOptions)
    //       // .then(response => response.json())
    //       .then(() => {
    //       //   handleSaveEventDrivers(); //recalcuate grid and refetch
            
    //         setAddPitStopModal({show: false});
    //         setLoader({show: false});
    //     })
    //       .catch((err) => console.log(err));
  
    //   } else {
    //     setAddPitStopModal({show: false});
    //   }
    // }
  
    // const handleEventConstructorSelection = (constructorName) => {
    //   const constructorMap = new Map();
    //   eventConstructors.forEach((entry) => {
    //     constructorMap.set(entry.constructor.name, entry.constructor.id);
    //   });
      
    //   let updatedAddPitStopModal = addPitStopModal;
  
    //   updatedAddPitStopModal.constructorName = constructorName;
    //   updatedAddPitStopModal.constructorId = constructorMap.get(constructorName);
  
    //   console.log('addPitStopModal)',addPitStopModal);
  
    //   setAddPitStopModal({...updatedAddPitStopModal}); // use a spread since setState didn't trigger a re-render
    // }
  
    // const handleEventDriverSelection = (driverName) => {
    //   const driverMap = new Map();
    //   drivers.forEach((d) => {
    //     driverMap.set(d.lastName, d.driverId);
    //   });
      
    //   let updatedAddPitStopModal = addPitStopModal;
  
  
  
    //   updatedAddPitStopModal.driverName = driverName;
    //   updatedAddPitStopModal.driverId = driverMap.get(driverName);
    //   console.log(updatedAddPitStopModal);
    //   console.log(addPitStopModal.constructorId===undefined || addPitStopModal.driverId===undefined || addPitStopModal.lap===undefined || addPitStopModal.duration===undefined);
    //   setAddPitStopModal({...updatedAddPitStopModal}); // use a spread since setState didn't trigger a re-render
    // }
  
    const handleEventPitStopChange = (e) => {
        //deep object copy is needed otherwise UseEffect wont fire
        let updatedPitStops = pitStops; 
        console.log(`handleEventPitStopChange ${e.target.name}`);
    
        if (e.target.name==='Lap') {
            updatedPitStops.lap = Number(e.target.value);
            console.log(`Updated Lap`);
        }
        
        if (e.target.name==='Duration') {
            updatedPitStops.duration = Number(e.target.value);
            console.log(`Updated Duration`);
        }
        
        setPitStops({...updatedPitStops});
      }
    
    return (
        <Modal show={config.show} onHide={handleCloseEvent} closeOnOverlayClick={true}>
            <Loader config={loader}></Loader>
            <h2>Event Settings</h2>
            <div className="label-adorner">
            {`{ eventId: ${config.eventId}, type: ${config.type}, sessionKey: ${config.sessionKey} }`}
            </div>

            <div className="divider"></div>

            <div className='flex justify-content-between'>
                <DropdownButton 
                    options={recentEventsList.map(e=>e.shorthand)}
                    value={selectedRecentEvent}
                    clickFunction={(value) => setSelectedRecentEvent(value)}
                    >
                </DropdownButton>
                <Button onClick={matchRecent}>Match with Recent</Button>
            </div>
            <div className='flex justify-content-between'>
                <Button variant="outline-light" onClick={matchOpenF1}>Match with OpenF1</Button>
            </div>
            <div>{message}</div>

            <div className="divider"></div>
            <Button onClick={importOpenF1DriverData}>Import OpenF1 Driver Data</Button>

            <div className='flex justify-content-between'>
                <h3>Drivers</h3>
                <Button variant="secondary" onClick={updateAllSessionDrivers}>Update All</Button>
            </div>
            <div className="table-container">
                <table className='table'>
                    <tr>
                        <th>Pos.</th>
                        <th className='td-10em'>Name</th>
                        <th className='td-4em'>Start</th>
                        <th className='td-4em'>End.</th>
                        <th className='td-4em'>Laps</th>
                        <th>Status</th>
                        <th>Fastest Lap</th>
                        <th>DotD</th>
                        <th>Lap</th>
                    </tr>
                { drivers !== undefined && drivers.length > 0 && (

                    drivers.map(d => (
                    <tr>
                        <td>{`${d.currentPosition!==null ? d.currentPosition : '-'}`}</td>

                        <td>
                            <div className='flex flex-column'>
                                <div className='text-align-left single-line'>{`[${d.driverNumber}] ${d.firstName} ${d.lastName}`}</div>
                                <div className='flex align-items-center' style={{gap: '4px'}}>
                                    <img className='constructor-logo-inline' src={d.constructorLogoUrl} alt={d.constructorName}></img>
                                    <div className='single-line'>{`${d.constructorName}`}</div>
                                </div>
                            </div>
                        </td>
                        

                        <td>
                            <input type="text" 
                                name="Start Position" 
                                value={d.startPosition}
                                onChange={(e) => handleEventDriversChange(e, d.driverId)} />
                        </td>
                        
                        <td>
                            <input type="text" 
                                name="End Position" 
                                value={d.endPosition}
                                onChange={(e) => handleEventDriversChange(e, d.driverId)} />
                        </td>
                    
                        <td>
                            <input type="text" 
                                name="Laps" 
                                value={d.lapsCompleted}
                                onChange={(e) => handleEventDriversChange(e, d.driverId)} />
                        </td>
                    
                        <td>
                            <DropdownButton 
                                options={statusOptions}
                                value={d.status}
                                clickFunction={(status) => handleEventDriversStatusChange(status, d.driverId)}
                            >
                            </DropdownButton>
                        </td>

                        <td>
                            <input
                                type="radio" 
                                name={`Fastest Lap`}
                                checked={d.fastestLap} 
                                onChange={(e) => handleEventDriverRadioButtonStatChange(e, d.driverId)} />
                        </td>

                        <td>
                            <input
                                type="radio" 
                                name={`DotD`}
                                checked={d.dotd} 
                                onChange={(e) => handleEventDriverRadioButtonStatChange(e, d.driverId)} />
                        </td>
                        
                        <td>
                            <div className='grid'>
                                <div>{formatDuration(d.bestLapTime)}</div>
                                <div>+{formatDuration(d.gap)}</div>
                            </div>
                        </td>

                        {/* <td>
                            <Button variant="icon" onClick={(e) => updateSessionDriver(e, d)}>
                                {d.action==='Add' ? (<AddCircleOutlineIcon fontSize='small' />) : (<SaveIcon fontSize='small' />)}
                            </Button>
                        </td> */}
                    
                    </tr>
                    ))
                )}
                </table>
            </div>

            
            <div className='flex justify-content-between'>
                <h3>Pit Stops</h3>
                <Button variant="secondary" onClick={updateAllSessionPitStops}>Update All</Button>
            </div>
            <div className="table-container">
                <table className='table'>
                    <tr>
                        <th>Lap</th>
                        <th className='td-10em'>Constructor</th>
                        <th className='td-10em'>Driver</th>
                        <th>Duration</th>
                    </tr>
                { pitStops !== undefined && pitStops.length > 0 && (

                    pitStops.map(p => (
                    <tr>
                        <td>
                            <input type="number" 
                                name="Lap" 
                                value={p.lap}
                                onChange={(e) => handleEventPitStopChange(e)} />
                        </td>
                        <td>{`${p.constructor.name}`}</td>
                        {/* <td>                                
                            <DropdownButton 
                            options={eventConstructors.map((entry) => entry.constructor.name)}
                            value={addPitStopModal.constructorName}
                            clickFunction={(selection) => handleEventConstructorSelection(selection)}
                            >
                            </DropdownButton>
                        </td> */}
                        <td>{`${p.driver.driverNumber}`}</td>

                        {/* <td>
                            <DropdownButton 
                            options={drivers.map((d) => d.lastName)}
                            value={addPitStopModal.driverName}
                            clickFunction={(selection) => handleEventDriverSelection(selection)} 
                            >
                            </DropdownButton>
                        </td> */}

                        <td>{`${p.duration}`}
                            
                            <input type="number" 
                                name="Duration" 
                                value={p.duration}
                                onChange={(e) => handleEventPitStopChange(e)} />
                        </td>

                        {/* <td>
                            <input type="text" 
                                name="Duration" 
                                value={d.startPosition}
                                onChange={(e) => handleEventDriversChange(e, d.driverId)} />
                        </td> */}
                    </tr>
                    ))
                )}
                </table>
            </div>

            <div className="modal-footer">
                <Button variant="secondary" onClick={handleCloseEvent}>Close</Button>
            </div>

        </Modal>

     );
    
}

export default PitStopSettings;