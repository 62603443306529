import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";

import Countdown from '../../components/Countdown/Countdown';
import Loader from '../../components/Loader/Loader';

import Button from '../../atoms/Button/Button';
import Chip from '../../atoms/Chip/Chip';

import './Schedule.css';

function Schedule({user}) {
    const navigate = useNavigate();

    const [schedule, setSchedule] = useState([]);    
    const [season] = useState(2024);
    const [showCountdown] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [clientWidth, setClientWidth] = useState(0);

    const [loader, setLoader] = useState({show: false, text: ''});
    
    useEffect(() => {
      // console.log('useEffect Schedule: ', schedule);
      fetchScheduleData()
    }, [])

    const fetchScheduleData = () => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
  
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            season: season
          })
      };
  
      setLoader({show: true, text: 'Loading Schedule...'});

      fetch(apiUrl + `/app/schedule`, requestOptions)
        .then(response => response.json())
        .then(data => {

            let revisedData = data.map(event => ({
              ...event,
              datetime: new Date(event.date)
            }));

            revisedData = addSignificance(revisedData);

            
            // console.log(revisedData);
            setSchedule(revisedData);
            setLoader({show: false});

            //giving slight delay so the schedule elements render
            setTimeout(() => {

              if (revisedData.some(e => e.significance === 'Today')) {
                document.getElementById("event-Today").scrollIntoView({ behavior: "smooth"});
  
              } else if (revisedData.some(e => e.significance === 'Next')) {
                document.getElementById("event-Next").scrollIntoView({ behavior: "smooth"});
              }
            
            },  10);

    })
        .catch((err) => {
          console.log(err);
          // setLoader({show: true, text: 'Error, try again.'});
        });
    }

    function addSignificance(events) {
      // Sort the events by date in ascending order
      events.sort((a, b) => new Date(a.date) - new Date(b.date));
  
      // Get today's date
      let today = new Date();
      today.setHours(0, 0, 0, 0);
  
      let previousEventIndex = -1;
  
      for (let i = 0; i < events.length; i++) {
          let eventDate = new Date(events[i].date);
          eventDate.setHours(0, 0, 0, 0);
  
          if (eventDate < today) {
              // If the event is in the past, remember its index
              previousEventIndex = i;
          } else if (eventDate.getTime() === today.getTime()) {
              // If the event is today, set significance to "Today!"
              events[i].significance = "Today";
          } else {
              // If the event is in the future, set significance to "Next"
              events[i].significance = "Next";
              break;  // We've found the next event, no need to continue the loop
          }
      }
  
      // Set significance to "Previous" for the most recent past event
      if (previousEventIndex !== -1) {
          events[previousEventIndex].significance = "Previous";
      }
  
      return events;
    }


    const goToEvent = (e, event, tab) => {
      e.stopPropagation();
      if(tab === undefined) {
        navigate(`/event/?event=${event.eventId}`);
      } else {        
        navigate(`/event/?event=${event.eventId}&tab=${tab}`);
      }
      
    }

    const handleScroll = (e) => {
      // console.log(`handleScroll: ${e.target.scrollLeft}`);
      // console.log(`clientWidth: ${e.target.clientWidth}`);
      // console.log(e.target);
      // Calculate scroll position (e.g., using e.target.scrollLeft)
      setScrollPosition(e.target.scrollLeft);
      setClientWidth(e.target.clientWidth);
    };

    const calcPosterTransform = (index) => {
      let posterWidth = 300 + 7;  //poster width + gap
      let breakpointA = scrollPosition;
      let breakpointB = scrollPosition + clientWidth;
      let posterCenterPoint = (index + 0.5)*posterWidth + 300 + 28;

      if(posterCenterPoint < (breakpointA)) {
        return 0.85;

      } else if((breakpointB) < posterCenterPoint) {
        return 0.85;

      } else {
        return 0.85 + 0.15 * Math.sin(Math.PI * ((posterCenterPoint-breakpointA)/clientWidth ));
      }
    }


  
    return ( 
      <div className="page-container schedule">
        <Loader config={loader}></Loader>

        {showCountdown && (
          <Countdown targetDate="2024-03-01T05:00:00" />
        )}
      
        <div className="carousel" onScroll={handleScroll}>
          {schedule.length > 0 && (
            schedule.map((event, index) => (
              <div id={event.significance !== null ? `event-${event.significance}` : `event-${event.eventId}`}
                className='carousel-item'
                style={{transform: `scale(${calcPosterTransform(index)})`}}>

                {event.significance !== undefined ? (
                  <Chip variant='basic'>{event.significance}</Chip>
                ) : (
                  <Chip variant='spacer'>&nbsp</Chip>
                )}

                <div className="event-poster" onClick={(e) => goToEvent(e, event)}>
                  <div className="event-poster-hero" style={{
                    backgroundImage: `linear-gradient(to bottom, 
                      rgba(0, 0, 0, 0) 30%, 
                      ${event.accentColor}), 
                      url(${event.posterUrl})`}}>
                    <div className="subtitle">
                      <Chip>ROUND {event.round}</Chip>
                      
                      {/* {highlighedEvent.sprintRace && (
                      <div className='sprint-label'>Sprint</div>
                      )} */}
                    </div>
                    
                    <div className='flex align-items-center gap-1'>
                      <img className="flag" alt={event.locality} src={event.flagUrl} />
                      <div>{event.locality}</div>
                      <img className="circuit-map" alt="track" src={event.trackSvgUrl} />
                    </div>
                  </div>

                  <div className='event-poster-info' style={{backgroundColor: `${event.accentColor}`}}>
                    <h2 className='single-line'>{event.eventName}</h2>
                    <div>{`${event.datetime.toLocaleString([], {weekday: 'long' })},
                      ${event.datetime.toLocaleString([], {month: 'short', day: 'numeric' })}
                      • ${event.datetime.toLocaleString([], {hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}`}</div>
                  </div>
                </div>

                { user.id !== null && (
                <div className="event-action">
                  
                    {event.formState === 'Draft' && (
                      <Button variant="outline-light" disabled >Submissions Open Soon!</Button>
                    )}

                    {event.formState === 'Live' && (
                        event.submissionState !== 'Submitted' ? (
                        <Button variant="primary" onClick={(e) => goToEvent(e, event, 'Picks')}>Submit Picks!</Button>
                        ):(
                        <Button variant="primary" onClick={(e) => goToEvent(e, event, 'Picks')}>View Picks!</Button>                      
                        )
                    )}
                    
                    {event.formState === 'Complete' && (
                      <Button variant="primary" onClick={(e) => goToEvent(e, event, 'Results')}>View Results</Button>
                    )}
                </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    );
}

export default Schedule;