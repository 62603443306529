import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '../../atoms/Button/Button';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { marked } from 'marked';
import './Rules.css';

function Rules() {
    const [markdown, setMarkdown] = useState('');
    const navigate = useNavigate();

    const notePattern = /\[!NOTE\]([\s\S]*?)(?=\n\s*\n|\n\s*!|\[!(NOTE|IMPORTANT)\])/g;
   
    const wrapImportantContent = (match, content) => {
        return `<div class="important is-info">${marked(content)}</div>`;
    };

    const wrapNoteContent = (match, content) => {
        return `<div class="note is-info">${marked(content)}</div>`;
    };
        
    useEffect(() => {
        fetch('https://raw.githubusercontent.com/MBuenaventura-Test/FF-Rules/main/README.md')
            .then(response => response.text())
            .then(text => {
                let modifiedText = text.replace(notePattern, wrapNoteContent);
                modifiedText = modifiedText.replace( wrapImportantContent);
                setMarkdown(marked(modifiedText));
            })
            .catch(err => console.error(err));
    }, []);
  

    const goBack = () => {
        navigate(-1);
    }

    return (
        <div className="page-container">
            


            <div className="page-header">
                <div>
                    <Button 
                        variant="link" 
                        onClick={(e) => goBack(e)}>
                        <NavigateBeforeIcon fontSize="small" />
                        Back
                    </Button>
                </div>
                <h2>Rules</h2>
                <div></div>
            </div>
            
            <div className='dark-card margin-inline-auto' style={{marginTop: '1em'}}>
                <div className="markdown-container" dangerouslySetInnerHTML={{ __html: markdown }} />
            </div>
        </div>
    )
}

export default Rules;