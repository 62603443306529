import React, { useState, useEffect } from 'react';
import Button from '../../atoms/Button/Button';
import UserChip from '../UserChip/UserChip';
import Chip from '../../atoms/Chip/Chip';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './Driver.css';

const Driver = ({ driver, variant, expanded, handleExpansion, inputClick, disabled, responsesClick }) => {

    const [isExpanded, setIsExpanded] = useState(expanded);

    const toggleAccordian = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        
        console.log('Toggle Accordian', !isExpanded);
        setIsExpanded(!isExpanded);
        handleExpansion(e, driver);
    }
    
    useEffect(() => {
        setIsExpanded(expanded);
    }, [ expanded ]);


  return (
    
    <div variant={variant} 
        className={`dark-card gap-0 driver
        ${variant==='input' ? (disabled ? 'disabled' : 'selectable') : ''} 
        ${driver.userAnswer!==undefined ? 'selected' : ''}`}
    
        onClick={variant==='input' ? ((e) => inputClick(e, driver,
            driver.startPosition<=5 ? 1 : (
                driver.startPosition<=10 ? 2 : 3)
          )) : ()=>{}}>

    <div className="main">

        {variant==='input' && (<>
        <div className='number'>
            <h3>{driver.status !== null ? driver.status : driver.startPosition}</h3>
        </div>

        <div className='input'>
            {driver.startPosition<=5 && (
            <input
            type="radio" 
            name={`User Answer Radio [${driver.id}] 1`}
            checked={driver.userAnswer===1} 
            onChange={(e) => inputClick(e, driver, 1)} 
            disabled={disabled} />)}

            {driver.startPosition>5 && driver.startPosition<=10 && (
            <input
            type="radio" 
            name={`User Answer Radio [${driver.id}] 2`}
            checked={driver.userAnswer===2} 
            onChange={(e) => inputClick(e, driver, 2)} 
            disabled={disabled} />)}

            {driver.startPosition>10 && (<input
            type="radio" 
            name={`User Answer Radio [${driver.id}] 3`}
            checked={driver.userAnswer===3} 
            onChange={(e) => inputClick(e, driver, 3)} 
            disabled={disabled} />)}
            
        </div>
        </>)}
                          
        <div className="driver-details driver-answer">
          <img className="answer-image medium" src={driver.profilePicUrl} alt="answer-img" />

          <div className='flex flex-column'>
            <div className='single-line'>{driver.firstName}</div>
            <h3 className='single-line'>{driver.lastName}</h3>
            <div className='flex align-items-center' style={{gap: '4px'}}>
              <img className='constructor-logo-inline' src={driver.constructorLogoUrl} alt={driver.constructorName}></img>
              <div className='single-line'>{`${driver.constructorName}`}</div>
            </div>
          </div>
        </div>
        
        <div className='stats'>
            <Button variant='icon-outline' onClick={(e) => toggleAccordian(e)}>
                <InsertChartOutlinedIcon fontSize='small' />
            </Button>
            
            {driver.points !== undefined && (
            <Chip>{driver.points}</Chip>
            )}
        </div>
    </div>
    
    <div className={`stats-accordian ${isExpanded ? 'open' : ''}`}>
        
        {driver.lastStats !== undefined && driver.lastStats !== null && (
        <div className='dark-card gap-05'>
            <div className='flex justify-content-between align-items-center'>
                <h4>Previous Race</h4>
                <Chip>{driver.lastStats.points}</Chip>
            </div>

            <p>
                <span>Laps: {driver.lastStats.lapsCompleted} </span> <br />

                {driver.lastStats.status===null && (<>
                    <span>Finished: P{driver.lastStats.endPosition} </span>
                    {driver.lastStats.changePosition>0 && (
                        <span style={{color: 'green'}}>(<ArrowDropUpIcon fontSize='small' />{driver.lastStats.changePosition})</span>
                    )}
                    {driver.lastStats.changePosition===0 && (
                        <span>(-)</span>
                    )}
                    {driver.lastStats.changePosition<0 && (
                        <span style={{color: 'red'}}>(<ArrowDropDownIcon fontSize='small' />{Math.abs(driver.lastStats.changePosition)})</span>
                    )}
                </>)}
                
                {driver.lastStats.status!==null && (
                <div className='flex gap-05 align-items-center'>
                    <div>Finished:</div>
                    <Chip>{driver.lastStats.status}</Chip>
                </div>
                )}
            </p>

            {(driver.lastStats.fastestLap || driver.lastStats.dotd) && (
            <div className='flex gap-05'>
                {driver.lastStats.fastestLap && (
                <Chip>Fastest Lap</Chip>
                )}
                {driver.lastStats.dotd && (
                <Chip>DotD</Chip>
                )}
            </div>
            )}
        </div>
        )}

        <div className='dark-card'>
            <div className='flex justify-content-between align-items-center'>
                <h4>This Race</h4>
                <Chip>{driver.currentStats.points}</Chip>
            </div>
            
            <p>
                {driver.currentStats.endPosition===null && (<>
                    <span>Starting: P{driver.currentStats.startPosition}</span>
                </>)}
                
                {driver.currentStats.endPosition!==null && (<>
                    <span>Laps: {driver.currentStats.lapsCompleted} </span>  <br />
                
                    {driver.currentStats.status===null && (<>
                        <span>Finished: P{driver.currentStats.endPosition} </span>
                        {driver.currentStats.changePosition>0 && (
                            <span style={{color: 'green'}}>(<ArrowDropUpIcon fontSize='small' />{driver.currentStats.changePosition})</span>
                        )}
                        {driver.currentStats.changePosition===0 && (
                            <span>(-)</span>
                        )}
                        {driver.currentStats.changePosition<0 && (
                            <span style={{color: 'red'}}>(<ArrowDropDownIcon fontSize='small' />{Math.abs(driver.currentStats.changePosition)})</span>
                        )}
                    </>)}
                    
                    {driver.currentStats.status!==null && (
                    <div className='flex gap-05 align-items-center'>
                        <div>Finished:</div>
                        <Chip>{driver.currentStats.status}</Chip>
                    </div>
                    )}
                </>)}
            </p>
            
            {(driver.currentStats.fastestLap || driver.currentStats.dotd) && (
            <div className='flex gap-05'>
                {driver.currentStats.fastestLap && (
                <Chip>Fastest Lap</Chip>
                )}
                {driver.currentStats.dotd && (
                <Chip>DotD</Chip>
                )}
            </div>
            )}
        </div>
    </div>
    
    {variant==='responses' && (
    <div className="submission-avatars" onClick={(e) => responsesClick(e, driver.submissions)}>
        {driver.submissions.map(sub => (
        <UserChip variant='avatar' user={sub.user[0]}></UserChip>
        ))}
    </div>
    )}

    </div>
  );
}

export default Driver;
