import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";

import ScheduleSettings from './ScheduleSettings/ScheduleSettings';
import Loader from '../../../components/Loader/Loader';
import Button from '../../../atoms/Button/Button';
import SettingsIcon from '@mui/icons-material/Settings';

import './ScheduleTab.css';

function ScheduleTab({user, event, fetchEventData, circuit, schedule}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loader, setLoader] = useState({show: false, text: ''});
    const [scheduleSettingsConfig, setScheduleSettingsConfig] = useState({show: false, eventId: 0});  
    
    const openScheduleSettings = () => {
        setScheduleSettingsConfig({
            show: true, 
            event,
            // locality: circuit.locality
        });
    }
    const hideScheduleSettings = () => {
        setScheduleSettingsConfig({show: false, eventId: 0});
        fetchEventData();
    }

    

    return (
    <div className='event-details'>
        <ScheduleSettings config={scheduleSettingsConfig} onHide={hideScheduleSettings}></ScheduleSettings>

        <div className='dark-card'>
            <div className='flex justify-content-between'>
                <h2>Schedule</h2>

                {user.isAdmin && (
                <Button variant='icon' onClick={openScheduleSettings}>
                    <SettingsIcon fontSize="small" />
                </Button>
                )}

            </div>
            
            <div className='grid gap-1'>
                { schedule !== undefined && schedule.length > 0 && (
                schedule.map(s => (
                    <div className='flex justify-content-between'>
                        <div><b>{s.type}</b></div>
                        <div className='grid justify-items-center'>
                            <div>{`${s.datetime.toLocaleString([], {weekday: 'short' })}, ${s.datetime.toLocaleString([], {month: 'short', day: 'numeric' })}`}</div>
                            <div>{`${s.datetime.toLocaleString([], {hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}`}</div>
                        </div>
                    </div>
                )))}
            </div>
        </div>

        <div className='dark-card'>
            <h2>{circuit.name}</h2>
            <div>{`${circuit.locality}, ${circuit.country}`} <img className="flag small" alt={circuit.locality} src={circuit.flagUrl} /></div>

            <img className="track-map" alt="track" src={circuit.trackUrl} />
        </div>
    </div>
    );
}

export default ScheduleTab;