import React, { useState, useEffect } from 'react';
import { auth, registerWithEmailAndPassword, logInWithEmailAndPassword, sendVerificationEmail } from '../../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import ReCAPTCHA from "react-google-recaptcha";
import Button from '../../atoms/Button/Button';

import './Auth.css';
 
const EmailSignUp = ({onClose}) => {
 
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState("");
    const [captchaPassed, setCaptchaPassed] = useState(false);
    const [user, loading, error] = useAuthState(auth);


    useEffect(() => {
        // if (loading) {
        //     console.log('EmailSignUp: loading:', loading);
        // } else if (error) {
        //     console.log('EmailSignUp: error:', error);
        // } else 
        // console.log('email',email);
        if (email!=='' && user) {
            // console.log('EmailSignUp: user:', user);
            // console.log('EmailSignUp: user.emailVerified:', user.emailVerified);
            if (user.emailVerified) {
                onClose();
            } else {
                onClose('Open Verify Email');
            }
        }
    }, [user, loading, error, onClose]);

    const onSubmit = async () => {     
        if (captchaPassed) {
            try {
                await registerWithEmailAndPassword(email, password);
            } catch (error) {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);

                if(errorCode === 'auth/email-already-in-use') {
                    setMessage('Email already exists. Try signing in instead.');

                    // try {
                    //     await logInWithEmailAndPassword(email, password);
                    //     onClose();
                    // } catch (error) {
                        
                    // }
                    
                    // await sendVerificationEmail(auth);

                } else if(errorCode === 'auth/weak-password') {
                    setMessage('Password should be at least 6 characters.');
                } else {
                    setMessage('Something went wrong.');
                }
            }


        } else {
            // Show an error message or prevent form submission
            console.log('Please complete the reCAPTCHA.');
        }
    }
    const handleRecaptchaChange = (token) => {
        // Handle the reCAPTCHA token (e.g., send it to your server for verification)
        // console.log('reCAPTCHA token:', token);
        setCaptchaPassed(true);
      };
    
    const handleOpenSignIn = () => {onClose('Open Sign In');}

    const handleBack = () => {onClose('Open Sign Up');}
 

    return (
        <div className='auth-popup-container'>
            <h2 className='text-align-center'>Email Sign Up</h2>

            <p className='text-align-center'>
                Already have an account? <Button variant="link" onClick={handleOpenSignIn}>Sign in</Button>
            </p>

            <form onSubmit={e => {e.preventDefault();}}>
                <div className='form-field'>
                    <label htmlFor="email-address">
                        Email Address
                    </label>
                    <input
                        type="email"
                        label="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}  
                        required
                        placeholder="Email address"
                    />
                </div>

                <div className='form-field'>
                    <label htmlFor="password">
                        Password
                    </label>
                    <input
                        type="password"
                        label="Create password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder="Password"
                    />
                </div>                
            </form>

            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={handleRecaptchaChange} />

                
            {message!=='' && (
                <p style={{color: 'red'}}>{message}</p>
            )}

            <Button variant="primary" onClick={onSubmit} disabled={email==='' || password==='' || !captchaPassed}>Continue</Button>

            <Button variant="outline-light" onClick={handleBack}>Back</Button>
        </div>
    )
}
 
export default EmailSignUp