import React, { useState, useEffect } from 'react';
import Linkify from 'react-linkify';

import Loader from '../../../components/Loader/Loader';
import Driver from '../../../components/Driver/Driver';
import Constructor from '../../../components/Constructor/Constructor';
import UserChip from '../../../components/UserChip/UserChip';

import Chip from '../../../atoms/Chip/Chip';
import Button from '../../../atoms/Button/Button';
import Modal from '../../../atoms/Modal/Modal';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import './Responses.css';

function Responses({user, event}) {
    const [fetchedData, setFetchedData] = useState(false);
    const [loader, setLoader] = useState({show: false,text: ''});
    const [answerSubmissions, setAnswerSubmissions] = useState({show: false, submissions: []});
    const [responses, setResponses] = useState({sections: [{questions: {}}]});
    const [tooltip, setTooltip] = useState({show: false, header: '', body: ''});
    

  const fetchFormList = () => {
    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          eventId: event.eventId
        })
    };

    setLoader({show: true, text: 'Loading Form...'});
    fetch(apiUrl + `/app/event/formList`, requestOptions)
      .then(response => response.json())
      .then(data => {
        setFetchedData(true);
          
        if(data.length>0) {
          fetchResponsesData(data[0]);
        } else {
          setLoader({show: false});
        }
      })
      .catch((err) => console.log(err));
  }
  

    const fetchResponsesData = (form) => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              formId: form.id,
              userId: user.id
            })
        };
    
        setLoader({show: true, text: 'Loading responses...'});
        fetch(apiUrl + `/app/form/responses`, requestOptions)
          .then(response => response.json())
          .then(data => {
              setFetchedData(true);
            
              setResponses({
                sections: data.sections
              });
              setLoader({show: false});
      })
          .catch((err) => console.log(err));
      }

      
    
      useEffect(() => {
        console.log('useEffect Responses: ', responses);

        if(!fetchedData) {
          fetchFormList();
        }

      }, [responses])

      const showTooltip = (e, header, body) => {
        setTooltip({show: true, header: header, body: body})
      }
      const closeTooltip = () => {
        setTooltip({show: false, header: '', body: ''})
      }

      const showAnswerSubmissions = (e, submissions) => {
        setAnswerSubmissions({show: true, submissions: submissions});
        console.log(`showAnswerSubmissions`);
        console.log(submissions);
      }
      const closeAnswerSubmissions = () => {
        setAnswerSubmissions({show: false, submissions: []});
      }
    
      const [currentOpenAccordian, setCurrentOpenAccordian] = useState(null);
    
      const openAccordian = (e, a) => {
        e.preventDefault();
        e.stopPropagation();
        
        if(currentOpenAccordian!==a.id) {
          console.log('Open Accordian', a.id);
          setCurrentOpenAccordian(a.id);
        } else {
          setCurrentOpenAccordian(null);
        }
      }
      const isOpenAccordian = (answer) => {
        if(currentOpenAccordian===answer.id) {
          console.log('Open Accordian =>', answer.id);
        }
        return currentOpenAccordian===answer.id;
      }
      
  
  return (
    <div className="responses">
    
        <Loader config={loader}></Loader>

        <Modal show={tooltip.show} onHide={closeTooltip} center="true" closeOnOverlayClick="true">
            <h2>{tooltip.header}</h2>

            <p>
              <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>{decoratedText}</a>
              )}>
                {tooltip.body}
              </Linkify>
            </p>
            
            <div className="modal-footer">
                <Button variant="secondary" onClick={closeTooltip}>Close</Button>
            </div>
        </Modal>

        <Modal show={answerSubmissions.show} onHide={closeAnswerSubmissions} center="true" closeOnOverlayClick="true">
            <h2>Picks</h2>
            
            <div className="answer-responses-container">
                {answerSubmissions.submissions.map(s => (
                  <UserChip user={s.user[0]}></UserChip>
                ))}
            </div>

            
            <div className="modal-footer">
                <Button variant="secondary" onClick={closeAnswerSubmissions}>Close</Button>
            </div>
        </Modal>      

                { responses.sections.length > 0 && responses.sections[0].id !== undefined && (
                responses.sections.map(s => (
                
                <div className="dark-card">
                  { s.title !== undefined && (
                  <div className="section-header">
                      <h2>{s.title}</h2>

                      {s.showDescription && (
                      <Button variant="icon" onClick={(e) => showTooltip(e, s.title, s.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                      )}
                  </div>
                  )}

                  <div className='section-questions-container'>
                    { s.questions.length > 0 && s.questions[0].id !== undefined && s.questions.map(q => (
                    <div>
                        <div className='question-header'>
                            <h4>{q.text}</h4>
                            
                            {q.description !== '' && (
                            <Button variant="icon" onClick={(e) => showTooltip(e, q.text, q.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                            )}
                        </div>
                        
                        <div className="answer-order-container">
                            {q.answerOrder.map(ao => (
                            <div>
                                <div className='answer-order-header'>

                                  {q.type === 'Podium Picks' && (
                                  <b>
                                    {ao.order === 1 && ('P1')}
                                    {ao.order === 2 && ('P2')}
                                    {ao.order === 3 && ('P3')}
                                  </b>)}

                                  {q.type === 'Driver Picks' && (
                                  <h3>
                                    {ao.order === 1 && ('Group A')}
                                    {ao.order === 2 && ('Group B')}
                                    {ao.order === 3 && ('Group C')}
                                  </h3>)}                              
                                </div>
                                
                                <div className='question-answers-container'>
                                  
                                {q.type === 'Driver Picks' && ao.answers.map(a => (
                                    <Driver driver={a} variant='responses'
                                      expanded={isOpenAccordian(a)} 
                                      handleExpansion={openAccordian}
                                      responsesClick={(e, s) =>showAnswerSubmissions(e, s)}></Driver>
                                  ))}

                                  {q.type === 'Constructor Picks' && ao.answers.map(a => (
                                    <Constructor constructor={a} variant='responses'
                                      expanded={isOpenAccordian(a)} 
                                      handleExpansion={openAccordian}
                                      responsesClick={(e, s) =>showAnswerSubmissions(e, s)}></Constructor>
                                  ))}
                                  
                                  {q.type === 'Bonus Radio' && ao.answers.map(a => (
                                    <div className={`dark-card gap-0`}>

                                      <div className='answer background-image-contain'
                                        style={{backgroundImage: `url(${a.logoUrl})`}}>

                                        <div className='answer-details'>
                                          <h3 className='le-line'>{a.text}</h3>
                                        </div>
                                      
                                        <div className='points'>
                                          
                                          <Chip>{a.points}</Chip>
                                        </div>
                                      </div>
                                      
                                      <div className="submission-avatars" onClick={(e) => showAnswerSubmissions(e, a.submissions)}>
                                        {a.submissions.map(sub => (
                                        <UserChip variant='avatar' user={sub.user[0]}></UserChip>
                                        ))}
                                      </div>
                                  </div>
                                  ))}
                                  
                                  {q.type === 'Podium Picks' && ao.answers.map(a => (
                                    <div className={`dark-card gap-0`}>

                                      <div className='answer background-image-contain'
                                        style={{backgroundImage: `url(${a.logoUrl})`}}>

                                        <div className='answer-details'>
                                          <div>{a.order}</div>
                                          <div className="driverCode">{a.code}</div>
                                        </div>
                                      
                                        <div className='points'>
                                          
                                          <Chip>{a.points}</Chip>
                                        </div>
                                      </div>
                                      
                                      <div className="submission-avatars" onClick={(e) => showAnswerSubmissions(e, a.submissions)}>
                                        {a.submissions.map(sub => (
                                        <UserChip variant='avatar' user={sub.user[0]}></UserChip>
                                        ))}
                                      </div>
                                  </div>
                                  ))}

                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                    ))}
                  </div>
                </div>
                ))
              )}

        {/* </div> */}
    </div>
    )
}

export default Responses;