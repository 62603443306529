import React, { useState, useEffect } from 'react';

import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';

import Loader from '../../../../components/Loader/Loader';
import AreYouSureModal from '../../../../components/AreYouSureModal/AreYouSureModal';

import SaveIcon from '@mui/icons-material/Save';

import './QuestionSettings.css';

function QuestionSettings({config, onHide}) {
    const [loader, setLoader] = useState({show: false, text: ''});
    const [deleteModalConfig, setDeleteModalConfig] = useState({show: false});
    
    useEffect(() => {
        if(config.show) {
            // fetchQuestionTypes();
            console.log('config', config);
        }

    }, [config]);

    const deleteQuestion = () => {
        setLoader({show: true, text: 'Deleting question...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
         
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              questionId: config.questionId
            })
        };
        
        fetch(apiUrl + `/app/form/question/delete2`, requestOptions)
          .then(response => response.json())
          .then(() => {    
            setLoader({show: false});
            onHide('Reload');
        })
          .catch((err) => console.log(err));
    }

    const openDeleteModal = () => {
        setDeleteModalConfig({show: true});
    }
    const closeDeleteModal = (action) => {
        setDeleteModalConfig({show: false});

        if(action === 'Confirm') {
            deleteQuestion();
        }
    }

    const handleCloseEvent = (action) => {
        onHide(action);
    }
    
    return (
        <Modal show={config.show} onHide={handleCloseEvent} closeOnOverlayClick={true}>
            <Loader config={loader}></Loader>
            <AreYouSureModal config={deleteModalConfig} onHide={closeDeleteModal}></AreYouSureModal>
            
            <div className="modal-header">
                <h2>Question Settings</h2>
                {/* <Button variant="primary" onClick={saveForm} disabled={!isFormDirty()}>Save <SaveIcon fontSize='small' /></Button> */}
            </div>
            <Button variant="warn" onClick={openDeleteModal}>Delete<SaveIcon fontSize='small' /></Button>

            <div className="modal-footer">
                <Button variant="outline-light" onClick={handleCloseEvent}>Cancel</Button>
            </div>

        </Modal>

     );
    
}

export default QuestionSettings;