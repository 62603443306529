import React, { useReducer, useState, useEffect } from 'react';

import Loader from '../../../../components/Loader/Loader';

import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';
import { DropdownButton } from '../../../../atoms/DropdownButton/DropdownButton';

import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import EmergencyIcon from '@mui/icons-material/Emergency';

import './ScheduleSettings.css';

function reducer(state, action) {
    console.log(`reducer (${action.type}, ${action.hasOwnProperty('payload')})`);   
    
    let newState = structuredClone(state);
    try {

        let session;
        
        if(action.hasOwnProperty('payload')) {
            
            // console.log(`typeof action.payload.eventId: ${typeof action.payload.eventId} (${action.payload.eventId})`);
            // console.log(`state: `, state);
            session = newState.findIndex(s => s.id === action.payload.eventId);
        }

        switch(action.type) {
            case 'initialize':
                return action.payload;
            
            case 'clear_matched_data':
                newState.forEach(s => { 
                    if (s.hasOwnProperty('matchedDate')) { delete s['matchedDate']; }
                    if (s.hasOwnProperty('matchedSessionKey')) { delete s['matchedSessionKey']; }
                    if (s.hasOwnProperty('matchedMeetingKey')) { delete s['matchedMeetingKey']; }
                });
                return newState;

            case 'delete_event':
                if(newState[session].action === 'add') {
                    newState.splice(session, 1);
                } else {
                    newState[session].action = 'delete';
                }
                return newState;

            case 'restore_event':
                newState[session].action = 'none';
                return newState;

            case '':
                newState[session].date = action.payload.date;
                newState[session].datetime = action.payload.datetime;
                newState[session].datetimestring = action.payload.datetimestring;
                return newState;

            case 'update_meeting_key':
                newState[session].meetingKey = action.payload.meetingKey;
                return newState;

            case 'update_session_key':
                newState[session].sessionKey = action.payload.sessionKey;
                return newState;

            case 'update_event_type':
                newState[session].type = action.payload.type;
                return newState;            

            case 'add_event':
                newState.push({
                    id: `new-${newState.length+1}`,
                    name: action.payload.name,
                    type: action.payload.type,
                    date: action.payload.date,
                    datetime: action.payload.datetime,
                    datetimestring: action.payload.datetimestring,
                    circuitId: action.payload.circuitId,
                    round: action.payload.round,
                    season: action.payload.season,
                    url: action.payload.url,
                    posterUrl: action.payload.posterUrl,
                    accentColor: action.payload.accentColor,
                    meetingKey: action.payload.meetingKey,
                    sessionKey: action.payload.sessionKey,
                    action: 'add',
                });
                newState.sort((a, b) => b.date - a.date);
                return newState;

            case 'update_matched_date':
                newState[session].matchedDate = action.payload.matchedDate;
                return newState;

            case 'update_matched_meeting_key':
                newState[session].matchedMeetingKey = action.payload.matchedMeetingKey;
                return newState;
                
            case 'update_matched_session_key':
                newState[session].matchedSessionKey = action.payload.matchedSessionKey;
                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}

function ScheduleSettings({config, onHide}) {
    const [message, setMessage] = useState('');
    const [hasRun, setHasRun] = useState(false);
    const [matchMessage, setMatchMessage] = useState('');
    const [dbData, setDbData] = useState();
    const [sessions, dispatch] = useReducer(reducer, []);
    const [loader, setLoader] = useState({show: false, text: ''});
    const [eventTypes, setEventTypes] = useState([
        'Grand Prix',
        'Qualifying',
        'Sprint',
        'Sprint Qualifying',
        'Practice 1',
        'Practice 2',
        'Practice 3'
    ]);
    
    useEffect(() => {
        if(config.show) {
            setHasRun(false);
            fetchEvents();
            console.log('config', config);
        }

    }, [config]);
    
    useEffect(() => {
        if(config.show) {
            console.log('useEffect [sessions]', sessions.length, hasRun);
            if(!hasRun) {
                fetchOpenF1Sessions();
            }
        }

    }, [sessions]);

    

    const fetchEvents = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
        console.log('fetchEvents: ', config);
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.event.eventId
            })
        };

        fetch(apiUrl + "/app/event/sessions", requestOptions)
          .then(response => response.json())
          .then(data => {
            data.forEach(d => d.date = new Date(d.date).toISOString());
            data.forEach(d => d.datetime = new Date(d.date));
            data.forEach(d => d.datetimestring = d.datetime.toISOString().slice(0, 16)); // YYYY-MM-DDTHH:DD
            data.forEach(d => d.action = 'none');
            setDbData(data);
            dispatch({type: 'initialize', payload: data});
            console.log('fetchEvents: /app/event/sessions', data);
          })
          .catch((err) => {
            console.log(err);
          });
    }
    
    const fetchOpenF1Sessions = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                year: config.event.season,
                eventName: config.event.name
            })
        };

        setLoader({show: true, text: 'Fetching from Open F1...'});
        fetch(apiUrl + "/import/fetchSessions", requestOptions)
          .then(response => response.json())
          .then(data => {
            data.forEach(d => {
                d.date = new Date(d.date).toISOString();
                d.datetime = new Date(d.date);
                d.datetimestring = d.datetime.toISOString().slice(0, 24); // YYYY-MM-DDTHH:DD
            }); 
            console.log('fetchOpenF1Sessions: /import/fetchSessions', data);
            setLoader({show: false});
            if(sessions.length>0) {                
                setHasRun(true);
                compareToOpenF1(data);                
            }
          })
          .catch((err) => {
            setLoader({show: false});
            console.log(err);
          });
    }
    
    const compareToOpenF1 = (openF1Data) => {
        setLoader({show: true, text: 'Comparing data with Open F1...'});
        //Assumes there is only one of each event type
        console.log('compareToOpenF1', openF1Data);

        //Clear all previously matched data
        dispatch({type: 'clear_matched_data'});

        //Find matched but different data
        let nMatches = 0;
        let nDifferences = 0;
        let payload;
        for (let i = 0; i < sessions.length; i++) {
            for (let j = 0; j < openF1Data.length; j++) {
                if(sessions[i].type === openF1Data[j].type) {
                    console.log('Match', openF1Data[j]);
                    nMatches++;
                    if(sessions[i].date !== openF1Data[j].date) {
                        nDifferences++;
                        payload = {
                            eventId: sessions[i].id, 
                            matchedDate: {
                                current: sessions[i].date, 
                                openF1: openF1Data[j].date, 
                                show: sessions[i].date === null ? 'OpenF1' : 'Current'
                            }
                        };
                        dispatch({type: 'update_matched_date', payload: payload});
                        
                        if (sessions[i].date === null) {
                            payload = {
                                eventId: sessions[i].id, 
                                date: openF1Data[j].date,
                                datetime: new Date(openF1Data[j].date),
                                datetimestring: new Date(openF1Data[j].date).toISOString().slice(0, 16) // YYYY-MM-DDTHH:DD
                            }
                            dispatch({type: '', payload: payload});
                        }
                        console.log(`mismatch date: ${sessions[i].id}`);
                    }
                    if(sessions[i].meetingKey !== openF1Data[j].meetingKey) {
                        nDifferences++;
                        payload = {
                            eventId: sessions[i].id, 
                            matchedMeetingKey: {
                                current: sessions[i].meetingKey, 
                                openF1: openF1Data[j].meetingKey, 
                                show: sessions[i].meetingKey === null ? 'OpenF1' : 'Current'
                            }
                        };
                        dispatch({type: 'update_matched_meeting_key', payload: payload});
                        
                        if (sessions[i].meetingKey === null) {
                            dispatch({type: 'update_meeting_key', payload: {eventId: sessions[i].id, meetingKey: openF1Data[j].meetingKey}});
                        }
                        console.log(`mismatch meetingKey: ${sessions[i].id}`);
                    }
                    if(sessions[i].sessionKey !== openF1Data[j].sessionKey) {
                        nDifferences++;
                        payload = {
                            eventId: sessions[i].id, 
                            matchedSessionKey: {
                                current: sessions[i].sessionKey, 
                                openF1: openF1Data[j].sessionKey, 
                                show: sessions[i].sessionKey === null ? 'OpenF1' : 'Current'
                            }
                        };
                        dispatch({type: 'update_matched_session_key', payload: payload});
                        
                        if (sessions[i].sessionKey === null) {
                            dispatch({type: 'update_session_key', payload: {eventId: sessions[i].id, sessionKey: openF1Data[j].sessionKey}});
                        }
                        console.log(`mismatch sessionKey: ${sessions[i].id}`);
                    }
                }
            }
        }

        //Add any missing from
        let matchExists = false;
        for (let j = 0; j < openF1Data.length; j++) {
            for (let i = 0; i < sessions.length; i++) {
                if(sessions[i].type === openF1Data[j].type) {
                    matchExists = true;
                }
            }

            //then this is a new event to be added
            if (matchExists === false) {
                // console.log('no match', openF1Data[j]);
                
                let payload = {
                    name: config.event.name,
                    type: openF1Data[j].type,
                    datetime: new Date(openF1Data[j].date),
                    datetimestring: new Date(openF1Data[j].date).toISOString().slice(0, 16), // YYYY-MM-DDTHH:DD,
                    date: new Date(openF1Data[j].date).toISOString(),
                    circuitId: config.event.circuitId,
                    round: config.event.round,
                    season: config.event.season,
                    url: null,
                    posterUrl: null,
                    accentColor: null,
                    meetingKey: openF1Data[j].meetingKey,
                    sessionKey: openF1Data[j].sessionKey,
                };
                dispatch({type: 'add_event', payload: payload});
            } else {
                matchExists = false;
            }
        }

        console.log('compareToOpenF1: nMatches', nMatches);
        if(openF1Data.length===0) {
            setMatchMessage('No sessions found in OpenF1.');
        } else {
            if(nMatches===0) {
                setMatchMessage(`Found ${openF1Data.length-nMatches} new sessions, and ${nDifferences} session differences from OpenF1.`);

            } else if (nMatches===openF1Data.length) {
                setMatchMessage('All sessions matched with OpenF1.');
            }
        }
        
        setLoader({show: false});
    }
    


    const save = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                events: sessions
            })
        };

        fetch(apiUrl + "/app/ref/saveEvents", requestOptions)
          .then(response => {
            if(response.status === 200) {
                setHasRun(false);
                fetchEvents();
            }
          })
        .catch((err) => {
            console.log(err);
        });
    }

    const swapMatchedData = (e, eventId, attribute) => {
        let s = sessions.findIndex(s => s.id === eventId);
        let payload;
        if (attribute === 'Date') {
            let newDate = sessions[s].matchedDate.show === 'Current' ? sessions[s].matchedDate.openF1 : sessions[s].matchedDate.current;
            payload = {
                eventId: eventId, 
                date: newDate,
                datetime: new Date(newDate),
                datetimestring: new Date(newDate).toISOString().slice(0, 16) // YYYY-MM-DDTHH:DD
            }
            dispatch({type: '', payload: payload});
            
            payload = {
                eventId: eventId, 
                matchedDate: {
                    current: sessions[s].matchedDate.current, 
                    openF1: sessions[s].matchedDate.openF1, 
                    show: sessions[s].matchedDate.show === 'Current' ? 'OpenF1' : 'Current'
                }
            };
            dispatch({type: 'update_matched_date', payload: payload});
            console.log(`matchedDate (${eventId}): `, sessions[s].matchedDate);
        } else if (attribute === 'Meeting Key') {
            console.log(`matchedMeetingKey (${eventId}): `, sessions[s].matchedMeetingKey);
        } else if (attribute === 'Session Key') {
            console.log(`matchedSessionKey (${eventId}): `, sessions[s].matchedSessionKey);
        } else {
            console.log(`Unknown attribute: ${attribute}`);
        }
    }



    const deleteEvent = (e, target) => {
        dispatch({type: 'delete_event', payload: {eventId: target.id}});
    }

    const restoreEvent = (e, target) => {
        dispatch({type: 'restore_event', payload: {eventId: target.id}});
    }

    const addManualEvent = (e) => {
        e.preventDefault();
        let newDate = new Date();
        let payload = {
            name: config.event.name,
            datetime: newDate,
            datetimestring: newDate.toISOString().slice(0, 16), // YYYY-MM-DDTHH:DD,
            date: newDate.toISOString(),
            circuitId: config.event.circuitId,
            round: config.event.round,
            season: config.event.season,
            url: null,
            posterUrl: null,
            accentColor: null,
            meetingKey: config.event.meetingKey,
            sessionKey: null
        };

        dispatch({type: 'add_event', payload: payload});
    }

    const handleDateChange = (e, eventId) => {
        let date = e.target.value;

        let payload = {
            eventId: eventId, 
            date: date,
            datetime: new Date(`${date}:00Z`),
            datetimestring: date
        }
        dispatch({type: '', payload: payload});

    }

    const handleMeetingKeyChange = (e, eventId) => {
        let meetingKey = parseInt(e.target.value, 10);
        dispatch({type: 'update_meeting_key', payload: {eventId: eventId, meetingKey: meetingKey}});
    }

    const handleSessionKeyChange = (e, eventId) => {
        let sessionKey = parseInt(e.target.value, 10);
        dispatch({type: 'update_session_key', payload: {eventId: eventId, sessionKey: sessionKey}});
    }

    const updateEventType = (eventId, type) => {
        dispatch({type: 'update_event_type', payload: {eventId: eventId, type: type}});
    }
    
    const isDirty = () => {
        let cleanedSessions = structuredClone(sessions);
        cleanedSessions.forEach(s => { 
            if (s.hasOwnProperty('matchedDate')) { delete s['matchedDate']; }
            if (s.hasOwnProperty('matchedSessionKey')) { delete s['matchedSessionKey']; }
            if (s.hasOwnProperty('matchedMeetingKey')) { delete s['matchedMeetingKey']; }
        });

        if (JSON.stringify(cleanedSessions) !== JSON.stringify(dbData)) { 
            // console.log('cleanedSessions', cleanedSessions);
            // console.log('dbData', dbData);
            return true; 
        } else {
            return false;
        }
    }

    const handleCloseEvent = () => {
        onHide();
    }
    
    return (
        <Modal show={config.show} onHide={handleCloseEvent} closeOnOverlayClick={true}>
            <Loader config={loader}></Loader>

            <div className="flex justify-content-between">
                <h2>Schedule Settings</h2>
                <Button variant="primary" onClick={save} disabled={!isDirty()}>Save <SaveIcon fontSize='small' /></Button>
            </div>

            <div className="label-adorner">
            {`{ eventId: ${config.event?.eventId} }`}
            </div>

            <div>{`${matchMessage}`}<Button variant='link' onClick={fetchOpenF1Sessions}>Search OpenF1 again</Button></div>

            <div className="divider"></div>

            <div style={{overflowX: 'auto'}}>
                <table className='table schedule-settings-table'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Type</th>
                            <th>Datetime (Z)</th>
                            <th>Meeting Key</th>
                            <th>Session Key</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                    { sessions !== undefined && sessions.length > 0 && (

                        sessions.map((s, i) => (
                        <tr style={s.action==='delete' ? {textDecoration: 'line-through'} : (s.action==='add' ? {color: 'dodgerblue'} : {})}>
                            <td>{s.id}</td>
                            <td>
                                <DropdownButton 
                                    options={eventTypes}
                                    value={s.type}
                                    clickFunction={(value) => updateEventType(s.id, value)}
                                    >
                                </DropdownButton>
                            </td>
                            <td>
                                <div>
                                    <div className='flex'>
                                        <input 
                                            style={(s.matchedDate !== undefined && s.matchedDate.show==='OpenF1') || s.action==='add' ? {color: 'dodgerblue'} : {}} 
                                            type="datetime-local" 
                                            value={s.datetimestring} 
                                            onChange={(e) => handleDateChange(e, s.id)} 
                                            name="Date" />
                                        {s.matchedDate !== undefined && (
                                            <Button variant="icon" style={{color: 'dodgerblue'}} onClick={(e) => swapMatchedData(e, s.id, 'Date')}><EmergencyIcon fontSize="small" /></Button>
                                        )}
                                    </div>
                                    <div>Local: {`${s.datetime.toLocaleString([], {weekday: 'short' })}, ${s.datetime.toLocaleString([], {month: 'short', day: 'numeric' })}, ${s.datetime.toLocaleString([], {hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}`}</div>
                                </div>
                            </td>
                            <td>
                                <div className='flex'>
                                    <input 
                                        style={(s.matchedMeetingKey !== undefined && s.matchedMeetingKey.show==='OpenF1') || s.action==='add' ? {color: 'dodgerblue'} : {}} 
                                        type="number" 
                                        value={s.meetingKey} 
                                        onChange={(e) => handleMeetingKeyChange(e, s.id)} 
                                        name="Meeting Key" />
                                    {s.matchedMeetingKey !== undefined && (
                                        <Button variant="icon" style={{color: 'dodgerblue'}} onClick={(e) => swapMatchedData(e, s.id, 'Meeting Key')}><EmergencyIcon fontSize="small" /></Button>
                                    )}
                                </div>
                            </td>
                            <td>
                                <div className='flex'>
                                    <input 
                                        style={(s.matchedSessionKey !== undefined && s.matchedSessionKey.show==='OpenF1') || s.action==='add' ? {color: 'dodgerblue'} : {}} 
                                        type="number" 
                                        value={s.sessionKey} 
                                        onChange={(e) => handleSessionKeyChange(e, s.id)} 
                                        name="Session Key" />
                                    {s.matchedSessionKey !== undefined && (
                                        <Button variant="icon" style={{color: 'dodgerblue'}} onClick={(e) => swapMatchedData(e, s.id, 'Session Key')}><EmergencyIcon fontSize="small" /></Button>
                                    )}
                                </div>
                            </td>
                            <td>
                                {s.action !== 'delete' && (
                                <Button variant="icon" onClick={(e) => deleteEvent(e, s)}><DeleteIcon fontSize='small' /></Button>
                                )}
                                {s.action === 'delete' && (
                                <Button variant="icon" onClick={(e) => restoreEvent(e, s)}><ReplayIcon fontSize='small' /></Button>
                                )}
                            </td>
                        
                        </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>
            
            <Button variant="outline-light" onClick={addManualEvent}>Add Event</Button>

            <div className="divider"></div>

            <div className="modal-footer">
                <Button variant="outline-light" onClick={handleCloseEvent}>Close</Button>
            </div>

        </Modal>

     );
    
}

export default ScheduleSettings;