import React, { useState, useEffect, useRef } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './DropdownButton.css';

// Usage: <Dropdown variant="chip" color="primary"></Dropdown>
// Variant:
//      (undefined)
//      primary
//      accent
//      warn

function DropdownButton({options, value, className, clickFunction}) {
    const [showMenu, setShowMenu] = useState(false);
    const dropdownRef = useRef(null);

    const toggleMenu = (state) => {
        if(state === undefined) {
            setShowMenu(!showMenu);
        } else if (state === true || state === false) {
            setShowMenu(state);
        }
    };

    const selectOption = (selectedOption, i) => {
        //return the option and index
        clickFunction(selectedOption, i);
        toggleMenu();
    };

    const handleClickOutside = (event) => { 
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) { 
            toggleMenu(false); 
        } 
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => { 
            document.removeEventListener('mousedown', handleClickOutside); 
        }; 
    }, []);

    return (
        <div className='ff-dropdown-container' ref={dropdownRef}>
            <div className='ff-dropdown' onClick={() => toggleMenu()}>
                <div className='flex-1'>{value}</div>
                
                <ExpandMoreIcon fontSize="small"/>
            </div>
            <div className={`ff-dropdown-menu ${showMenu ? `show` : ``}`}>
                {options.map((option, i) => (
                <div className={`ff-dropdown-item`} onClick={() => selectOption(option, i)}>
                    {option}
                </div>

                ))}
            </div>
        </div>
    )
}

export {DropdownButton};