import React, { useState, useEffect } from 'react';

import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';

import Loader from '../../../../components/Loader/Loader';

import SaveIcon from '@mui/icons-material/Save';

import './AddQuestion.css';

function AddQuestion({config, onHide}) {
    const [loader, setLoader] = useState({show: false, text: ''});
    const [questionTypes, setQuestionTypes] = useState([]);
    const [selectedType, setSelectedType] = useState();
    
    useEffect(() => {
        if(config.show) {
            fetchQuestionTypes();
            console.log('config', config);
        }

    }, [config]);

    const fetchQuestionTypes = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };
    
        setLoader({show: true, text: 'Fetching Question Types...'});
        fetch(apiUrl + `/app/form/question/types`, requestOptions)
          .then(response => response.json())
          .then(data => {
            setQuestionTypes(data);
            setLoader({show: false});
          })
          .catch((err) => console.log(err));
    }


    const saveQuestion = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userId: config.userId,
                formId: config.formId,
                type: selectedType,
                afterSection: config.afterSection
            })
        };
        
      setLoader({show: true, text: 'Adding question...'});
      fetch(apiUrl + '/app/form/question/addQuestion', requestOptions)
        .then(response => {
            if(response.status !== 200) {throw new Error("Something went wrong!");}
        })
        .then(() => {
            handleCloseEvent('Reload');
            setLoader({show: false});
        })
        .catch((err) => {
            console.log(err)
            setLoader({show: false});
        });
    }


    const handleQuestionTypeChange = (_, name) => {
        setSelectedType(name);
    }

    const handleCloseEvent = (action) => {
        onHide(action);
        setSelectedType();
    }
    
    return (
        <Modal show={config.show} onHide={handleCloseEvent} closeOnOverlayClick={true}>
            <Loader config={loader}></Loader>
            
            <div className="modal-header">
                <h2>Select a Question Type</h2>
            </div>

            <div className='grid gap-1'>
            {questionTypes.map(t => (
                <div className='selection-item'>
                    <div className="dark-card selectable"
                      onClick={(e) => handleQuestionTypeChange(e, t.name)} >

                      <div className='padding-05 flex gap-05 align-items-center'>
                        <div className='input'>
                          <input
                                name={t.name}
                                type="radio"
                                checked={selectedType === t.name} 
                                onChange={(e) => handleQuestionTypeChange(e, t.name)} />
                        </div>
                        <div className="image-text">{t.name}</div>
                      </div>

                    </div>
                </div>
            ))}
            </div>

            <div className="modal-footer">
                <Button variant="outline-light" onClick={handleCloseEvent}>Cancel</Button>
                <Button variant="primary" onClick={saveQuestion} disabled={selectedType===undefined}>Save & Close<SaveIcon fontSize='small' /></Button>
            </div>

        </Modal>

     );
    
}

export default AddQuestion;