import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import TabGroup from '../../atoms/Tabs/TabGroup';
import Tab from '../../atoms/Tabs/Tab';

import UsersTab from './UsersTab/UsersTab';
import EventsTab from './EventsTab/EventsTab';
import CircuitsTab from './CircuitsTab/CircuitsTab';
import DriversTab from './DriversTab/DriversTab';
import ConstructorsTab from './ConstructorsTab/ConstructorsTab';

import './Settings.css';


function Settings({user}) {
    const navigate = useNavigate();    
    const [activeTab, setActiveTab] = useState('Users');

    useEffect(() => {
        if(user.id === null) {
            navigate(`/`);
        }
    }, []);

    const handleTabChange = (newTab) => {
        console.log(newTab)
        setActiveTab(newTab);
    }

    return (
        <div className="settings-page">

            <TabGroup>
                <Tab label='Leagues' activeTab={activeTab} onClick={handleTabChange} />
                <Tab label='Users' activeTab={activeTab} onClick={handleTabChange} />
                <Tab label='Events' activeTab={activeTab} onClick={handleTabChange} />
                <Tab label='Circuits' activeTab={activeTab} onClick={handleTabChange} />
                <Tab label='Drivers' activeTab={activeTab} onClick={handleTabChange} />
                <Tab label='Constructors' activeTab={activeTab} onClick={handleTabChange} />
            </TabGroup>


            <div className='tab-outlet'>
                {activeTab === "Leagues" && (<></>)}
                {activeTab === "Users" && (<UsersTab></UsersTab>)}
                {activeTab === "Events" && (<EventsTab></EventsTab>)}
                {activeTab === "Circuits" && (<CircuitsTab></CircuitsTab>)}
                {activeTab === "Drivers" && (<DriversTab></DriversTab>)}                
                {activeTab === "Constructors" && (<ConstructorsTab></ConstructorsTab>)}
            </div>
        </div>
    )
}

export default Settings;