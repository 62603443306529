import React, { useState, useEffect } from 'react'

import Loader from '../../components/Loader/Loader';

import './Leagues.css';

function Leagues({user}) {
    const [loader, setLoader] = useState({show: false, text: ''});


    return (
        <div className="page-container">
            <Loader config={loader}></Loader>
            <h2>Leagues</h2>

        </div>
    )
}

export default Leagues;