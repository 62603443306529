import React, { useReducer, useEffect, useState } from 'react';

import Loader from '../../../components/Loader/Loader';
import Tooltip from '../../../components/Tooltip/Tooltip';
import Driver from '../../../components/Driver/Driver';
import Constructor from '../../../components/Constructor/Constructor';

import PicksSettings from './PicksSettings/PicksSettings';
import AddQuestion from './AddQuestion/AddQuestion';
import QuestionSettings from './QuestionSettings/QuestionSettings';

import { DropdownButton } from '../../../atoms/DropdownButton/DropdownButton';
import Button from '../../../atoms/Button/Button';

import SettingsIcon from '@mui/icons-material/Settings';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './PicksTab.css';

function reducer(state, action) {
  console.log(`reducer (${action.type}, ${action?.payload})`);   
  
  let newState = structuredClone(state);
  try {

      let i = action?.payload?.index;

      switch(action.type) {
          case 'initialize':
              return action.payload;

          case 'toggle_expand_question_answers':
              newState.form.section.forEach((section, sIndex) => {
                if (section.id === action.payload.sectionId) {
                  section.question.forEach((question, qIndex) => {
                    if (question.id === action.payload.questionId) {
                      let expandedArray = JSON.parse(JSON.stringify(question.expanded));
                      let order = action.payload.order;
                      if (expandedArray[order] === 'true') {
                        expandedArray[order] = 'false';
                      } else {
                        expandedArray[order] = 'true';
                      }
                      newState.form.section[sIndex].question[qIndex].expanded = expandedArray;
                    }
                  });
                }
              });
              return newState;


          case 'set_pick':
            let order = action.payload.order;
            newState.form.section.forEach((section, sIndex) => {
              if (section.id === action.payload.sectionId) {
                section.question.forEach((question, qIndex) => {
                  if (question.id === action.payload.questionId) {
                    question.answer.forEach((answer, aIndex) => {
                      if (answer.id === action.payload.answerId) {
                        // if (event.target.getAttribute('name').startsWith('User Answer Radio')) {
                          newState.form.section[sIndex].question[qIndex].answer.forEach((el)=>{if(el.userAnswer === order) {el.userAnswer = undefined}});

                          
                          newState.form.section[sIndex].question[qIndex].answer[aIndex].userAnswer = order;

                          let expandedArray = JSON.parse(JSON.stringify(question.expanded));
                          
                          if(expandedArray[order] === 'true') {
                            expandedArray[order] = 'false';
                          } else {
                            expandedArray[order] = 'true';
                          }
                          newState.form.section[sIndex].question[qIndex].expanded = expandedArray;
                      }
                    });
                  }
                });
              }
            });
            return newState;

          default:
              console.log(`Action ${action.type} not known.`);
              return newState;
      }
  }
  catch (error) {
      console.error(`An error occurred (${action.type}):`, error);
      return newState;
  }
}


function PicksTab({user, event}) {
    const [loader, setLoader] = useState({show: false, text: ''});

    const [picksSettingsConfig, setPicksSettingsConfig] = useState({show: false});  
    const [tooltipConfig, setTooltipConfig] = useState({show: false});
    const [addQuestionModal, setAddQuestionModal] = useState({show: false});
    const [questionSettingsModal, setQuestionSettingsModal] = useState({show: false});
    const [currentOpenAccordian, setCurrentOpenAccordian] = useState(null);

    const [picks, dispatch] = useReducer(reducer, []);


    useEffect(() => {
        console.log('event', event);
        if(user.id !== undefined && user.id !== null) {
          fetchPicks();
        }
    }, [user, event]);

    const fetchPicks = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            eventId: event.eventId,
            userId: user.id
          })
      };
    
      setLoader({show: true, text: 'Loading Picks...'});
      fetch(apiUrl + `/app/picks`, requestOptions)
        .then(response => response.json())
        .then(data => {

          if(data.form !== undefined) {

            data.form.section.sort((a, b) => a.order - b.order);

            for (const [i, ] of Object.entries(data.form?.section)) {
              for (const [j, ] of Object.entries(data.form?.section[i].question)) {
                data.form.section[i].question[j].expanded = [];

                for (const [k, ] of Object.entries(data.form?.section[i].question[j].answer)) {
                  if (Number.isInteger(data.form.section[i].question[j].answer[k].userAnswer)) {
                    data.form.section[i].question[j].expanded[data.form.section[i].question[j].answer.userAnswer] = 'false';
                  }
                }
              }
            }
          }

          dispatch({type: 'initialize', payload: data});
          setLoader({show: false});

          if(data.form?.formId === undefined) {
            createForm();
          } else if(data.submission?.submissionId === undefined) {
            createSubmission();
          }
        })
        .catch((err) => {
            console.log(err);
            setLoader({show: false});
        });
    }

    
    const createForm = () => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              eventId: event.eventId
          })
      };

      setLoader({show: true, text: 'Creating Form...'});
      fetch(apiUrl + "/app/form/create", requestOptions)
        .then(response => response.json())
        .then(data => {
          setLoader({show: false});
          createSubmission();
        })
        .catch((err) => {
          console.log(err);
          setLoader({show: false});
        });
    }
      
    const createSubmission = () => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            eventId: event.eventId, 
            userId: user.id 
          })
      };

      setLoader({show: true, text: 'Creating Submission...'});
      fetch(apiUrl + "/app/submission/create", requestOptions)
        .then(response => response.json())
        .then(data => {
          setLoader({show: false});
          fetchPicks();
        })
        .catch((err) => {
          console.log(err);
          setLoader({show: false});
        });
    }

    // Add Question Modal
    const openAddQuestionModal = (afterSection) => {
      setAddQuestionModal({show: true, userId: user.id, formId: picks.form?.formId, afterSection: afterSection});
    }
    const closeAddQuestionModal = (action) => {
      console.log('closeAddQuestionModal', action);
      if(action === 'Reload') {
        fetchPicks();
      }
      setAddQuestionModal({show: false});
    }

    // Question Settings Modal
    const openQuestionSettings = (e, questionId) => {
      setQuestionSettingsModal({show: true, questionId: questionId});
    }
    const closeQuestionSettings = (action) => {
      if(action === 'Reload') {
        fetchPicks();
      }
      setQuestionSettingsModal({show: false});
    }

    const toggleExpandQuestionAnswers = (e, sectionId, questionId, order) => {
      dispatch({type: 'toggle_expand_question_answers', payload: {sectionId: sectionId, questionId: questionId, order: order}});
    }

    const handleUserAnswerChange = (event, sectionId, questionId, answerId, order) => {
      console.log(`handleUserAnswerChange`);
      // if(form.state==='Complete') return
      if(picks.submission.submissionState==='Submitted') return


      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            submissionId: picks.submission.submissionId, 
            questionId: questionId,
            answerId: answerId,
            order: order,
            replace: true
          })
      };

      fetch(apiUrl + "/app/submission/answer/set", requestOptions)
        .then(response => response.json())
        .then(() => {
          dispatch({type: 'set_pick', payload: {sectionId, questionId, answerId, order}});
          
      })
        .catch((err) => console.log(err));
    }

    // Answer Accordian
    const openAccordian = (e, a) => {
      e.preventDefault();
      e.stopPropagation();
      
      if(currentOpenAccordian!==a.id) {
        console.log('Open Accordian', a.id);
        setCurrentOpenAccordian(a.id);
      } else {
        setCurrentOpenAccordian(null);
      }
    }
    const isOpenAccordian = (answer) => {
      if(currentOpenAccordian===answer.id) {
        console.log('Open Accordian =>', answer.id);
      }
      return currentOpenAccordian===answer.id;
    }

    // Picks Settings
    const openPicksSettings = () => {
      console.log('openFormSettings');

      let form = {
        formId: picks.form.formId,
        state: picks.form.state,
        memeUrl: picks.form.memeUrl,
        submissionDeadline: picks.form.submissionDeadline
      }

      let submission = {
        submissionId: picks.submission.submissionId,
        state: picks.submission.submissionState
      }

      setPicksSettingsConfig({
          show: true, 
          form: form,
          submission: submission
      });
    }
    const hidePicksSettings = (action) => {
      setPicksSettingsConfig({show: false});
      if(action === 'Reload') {
        fetchPicks();
      }
    }  

    // Tooltip
    const showTooltip = (e, header, body) => {
      setTooltipConfig({show: true, header: header, body: body});
    }
    const closeTooltip = () => {
      setTooltipConfig({show: false, header: '', body: ''});
    }
    

    return (
    <div className='event-details'>
        <Loader config={loader}></Loader>

        <PicksSettings config={picksSettingsConfig} onHide={hidePicksSettings}></PicksSettings>
        <Tooltip config={tooltipConfig} onHide={closeTooltip}></Tooltip>
        <AddQuestion config={addQuestionModal} onHide={closeAddQuestionModal}></AddQuestion>
        <QuestionSettings config={questionSettingsModal} onHide={closeQuestionSettings}></QuestionSettings>

        <div className='flex justify-content-between'>
          {user.isAdmin && (
          <Button variant='icon' onClick={openPicksSettings}>
              <SettingsIcon fontSize="small" />
          </Button>
          )}
        </div>

        <div className="adder" onClick={() => openAddQuestionModal(null)}>
          <div className="adder-line">
            <div className="adder-label"><AddIcon fontSize="small" />Add Question</div>
          </div>
        </div>

        { picks.form?.section.length > 0 && picks.form.section.map(s => (<>
          <div className="dark-card section margin-inline-auto scrollable" key={`s${s.id}`} id={s.id}>

            { s.title!==undefined && (
            <div className="section-header">
                <h2>{s.title}</h2>

                {s.showDescription && (
                <Button variant="icon" onClick={(e) => showTooltip(e, s.title, s.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                )}
            </div>
            )}
            
            { s.question.length > 0 && s.question[0].id !== undefined && (
              s.question.map(q => (<>
              <div className='question-header'>
                <div className="flex justify-content-between align-items-center">
                  <h3>{q.text}</h3>
                  <div className='flex'>
                    {q.showDescription && (
                    <Button variant="icon" onClick={(e) => showTooltip(e, q.text, q.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                    )}

                
                    {user.isAdmin && (
                    <Button variant='icon' onClick={(e) => openQuestionSettings(e, q.id)}>
                        <SettingsIcon fontSize="small" />
                    </Button>
                    )}
                    {/* {q.type !== 'Driver Picks' && (
                    <Button variant='icon' onClick={(e)=>toggleExpandQuestionAnswers(e, s.id, q.id, 0)}>
                      {q.expanded[0] === 'true' ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                    </Button>
                    )} */}
                  </div>
                </div>
              </div>

              { q.answer.length > 0 && q.answer[0].id !== undefined && (<>

                {q.type === 'Driver Picks' && (<>
                  <div className='flex justify-content-between'>
                    <h3>Group A</h3>
                    <Button variant='icon' onClick={(e)=>toggleExpandQuestionAnswers(e, s.id, q.id, 1)}>
                      {q.expanded[1] === 'true' ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                    </Button>
                  </div>
                  <div className='selection-container'
                    expanded={q.expanded[1]}>
                    {q.answer.filter(a=>a.startPosition<6).map(a => (
                    <div className='selection-item'>
                      <Driver driver={a} variant='input' 
                        expanded={isOpenAccordian(a)} 
                        handleExpansion={openAccordian} 
                        inputClick={(e, a, order) => handleUserAnswerChange(e, s.id, q.id, a.id, order)}
                        disabled={picks.submission.submissionState==='Submitted'}></Driver>
                    </div>
                    ))}
                  </div>
                  
                  <div className='flex justify-content-between'>
                    <h3>Group B</h3>
                    <Button variant='icon' onClick={(e)=>toggleExpandQuestionAnswers(e, s.id, q.id, 2)}>
                      {q.expanded[2] === 'true' ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                    </Button>
                  </div>
                  <div className='selection-container'
                    expanded={q.expanded[2]}>
                    {q.answer.filter(a=>a.startPosition>5 && a.startPosition<11).map(a => (
                    <div className='selection-item'>
                      <Driver driver={a} variant='input' 
                        expanded={isOpenAccordian(a)} 
                        handleExpansion={openAccordian} 
                        inputClick={(e, a, order) => handleUserAnswerChange(e, s.id, q.id, a.id, order)}
                        disabled={picks.submission.submissionState==='Submitted'}></Driver>
                    </div>
                    ))}
                  </div>
                  
                  <div className='flex justify-content-between'>
                    <h3>Group C</h3>
                    <Button variant='icon' onClick={(e)=>toggleExpandQuestionAnswers(e, s.id, q.id, 3)}>
                      {q.expanded[3] === 'true' ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                    </Button>
                  </div>
                  <div className='selection-container'
                    expanded={q.expanded[3]}>
                    {q.answer.filter(a=>a.startPosition>10).map(a => (
                    <div className='selection-item'>
                      <Driver driver={a} variant='input' 
                        expanded={isOpenAccordian(a)} 
                        handleExpansion={openAccordian} 
                        inputClick={(e, a, order) => handleUserAnswerChange(e, s.id, q.id, a.id, order)}
                        disabled={picks.submission.submissionState==='Submitted'}></Driver>
                    </div>
                    ))}
                  </div>
                </>)}


                {q.type === 'Constructor Picks' && (
                <div className='selection-container'
                  expanded={q.expanded[0]}>
                  {q.answer.map(a => (
                  <div className='selection-item'>
                    <Constructor constructor={a} variant='input' 
                      expanded={isOpenAccordian(a)} 
                      handleExpansion={openAccordian} 
                      inputClick={(e, a, order) => handleUserAnswerChange(e, s.id, q.id, a.id, order)}
                      disabled={picks.submission.submissionState==='Submitted'}></Constructor>
                  </div>
                  ))}
                </div>
                )}


                {q.type === 'Bonus Radio' && (
                <div className='selection-container'
                  expanded={q.expanded[0]}>
                  {q.answer.map(a => (
                  <div className='selection-item'>
                    <div className={`dark-card selectable gap-0 
                      ${a.imageUrl !== undefined && a.imageUrl !== '' && 'background-image-cover'}
                      ${a.userAnswer!==undefined ? 'selected' : ''} 
                      ${picks.submission.submissionState==='Submitted' ? 'disabled' : ''}`}
                    
                      style={a.imageUrl !== undefined && a.imageUrl !== '' ? {backgroundImage: `linear-gradient(to bottom, 
                        rgba(0, 0, 0, 0) 30%, 
                        var(--dark-4)), 
                        url(${a.imageUrl})
                        `,
                        flexShrink: 0,
                        justifyContent: 'flex-end'} : {}}
                      
                      onClick={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 0)} >

                      <div className='answer'>
                        <div className='input'>
                          <input
                              type="radio"
                              checked={a.userAnswer!==undefined} 
                              onChange={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 0)} 
                              disabled={picks.submission.submissionState==='Submitted'} />
                        </div>
                        
                        <div className='bonus-radio-details'>
                          <div className="image-text">{a.text}</div>

                          {a.description !== undefined && a.description !== '' && (
                          <Button variant="icon" onClick={(e) => showTooltip(e, a.text, a.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                          )}
                        </div>
                      </div>

                    </div>
                  </div>))}
                </div>
                )}

              </>)}
            </>))
            )}
          </div>
          

          <div className="adder" onClick={() => openAddQuestionModal(s.id)}>
            <div className="adder-line">
              <div className="adder-label"><AddIcon fontSize="small" />Add Question</div>
            </div>
          </div>
      </>))}

    </div>
    );
}

export default PicksTab;