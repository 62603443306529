import React, { useState, useEffect } from 'react';
import Button from '../../atoms/Button/Button';
import UserChip from './UserChip';

import './UserChip.css';


function CustomizeUserChip({user, setUser, onClose}) {
    // const [loader, setLoader] = useState({show: false, text: ''});
    const [message, setMessage] = useState("");
    const [updatedUser, setUpdatedUser] = useState(user);
    const [canSave, setCanSave] = useState(onClose!==undefined ? true : false); //defined if in a modal
    
    useEffect(() => {
        setUpdatedUser(user);    
    }, [user])

    const colors = [
        '#E81D02', 
        '#025FE8', 
        '#B3E802', 
        '#933E32', 
        '#354A69', 
        '#7D9332', 
        '#325993', 
        '#5C6935',
        '#D6E7FF',
        'rgb(120, 0, 140)'
    ];
    
    const handleCustomizationChange = (event) => {
        let tempProfile = JSON.parse(JSON.stringify(updatedUser)); 
        console.log(`handleCustomizationChange [${event.target.name}]`);

        setMessage('');
        let isValid = false;

        if (event.target.name === 'Display Name') {
            tempProfile.displayName = event.target.value;

            if (tempProfile.displayName === '') {
                setMessage('Display name must not be blank');
            } else {
                isValid = true;
            }
    
        } else if (event.target.name === 'Color') {
            tempProfile.color = event.target.value;
            isValid = true;
    
        } else if (event.target.name === 'Avatar Url') {
            tempProfile.avatarUrl = event.target.value;
            isValid = true;
        }

        setUpdatedUser(tempProfile);

        let isDirty = JSON.stringify(user) !== JSON.stringify(tempProfile);
        if(isDirty && isValid) {
            setCanSave(true);
        } else {
            setCanSave(false);
        }
    }

    const handleClearAvatarUrl = () => {
        let tempProfile = JSON.parse(JSON.stringify(updatedUser)); 
        tempProfile.avatarUrl = '';
        setUpdatedUser(tempProfile);
    }

    const handleCancel = () => {
        setUpdatedUser(user);
        if(onClose!==undefined) { //defined if in a modal
            onClose();
        }
    }

    const handleSave = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: user.id, 
                firstName: updatedUser.firstName, 
                lastName: updatedUser.lastName, 
                displayName: updatedUser.displayName, 
                email: updatedUser.email, 
                avatarUrl: updatedUser.avatarUrl,
                color: updatedUser.color
            })
        };

        // setLoader({show: true, text: 'Saving profile...'});
        fetch(apiUrl + "/user/update", requestOptions)
            .then(response => response.json())
            .then(data => {
                // setLoader({show: false});
                setUser(updatedUser);
                setCanSave(false);

                if(onClose!==undefined) { //defined if in a modal
                    onClose();
                }
            })
        .catch((err) => {
            console.log(err);
            // setLoader({show: false}); 
            setMessage('Unable to save profile.');
        });
    }

    return (<>
        {/* <Loader config={loader}></Loader> */}

        <form className="customizer"  onSubmit={e => {e.preventDefault();}}>
            
            <div className="dark-card flex flex-column">
                <h3>Preview</h3>
                <div className='flex-col-center'>
                    <UserChip user={updatedUser}></UserChip>
                </div>
            </div>

            <div className='grid'>
                <label>Display Name</label>
                <input 
                    type="dname" 
                    value={updatedUser.displayName} 
                    onChange={handleCustomizationChange} 
                    name="Display Name" />
            </div>

            <div className='grid'>
                <label>Avatar URL</label>
                <div className='flex align-items-center'>
                    <input 
                        style={{flexGrow: 1}}
                        type="text" 
                        value={updatedUser.avatarUrl} 
                        onChange={handleCustomizationChange} 
                        name="Avatar Url" />
                    <Button onClick={handleClearAvatarUrl}>Clear</Button>
                </div>
            </div>

            <div className='grid'>
                <label>Color Picker</label>
                <div className='color-picker'>
                    {colors.map((color, i) => (<>
                        <input id={`color-swatch-${i}`} name="Color" type="radio" value={color}
                            onChange={handleCustomizationChange} 
                            checked={color===updatedUser.color}
                            />
                        <label for={`color-swatch-${i}`} className="color-swatch" style={{backgroundColor: color}}></label>
                    </>))}
                </div>
            </div>
            
            <div className='flex gap-1'>
                <Button variant="primary" onClick={handleSave} value="Save" className="save-btn" disabled={!canSave}>Save</Button>
                <Button onClick={handleCancel}>Cancel</Button>
            </div>
            {message.length > 0 && (
                <p>{message}</p>
            )}
        </form>
    </>)
}


export default CustomizeUserChip