import React, { useState, useEffect } from 'react';
import { auth, sendVerificationEmail } from '../../firebase';
import Button from '../../atoms/Button/Button';
import { useAuthState } from "react-firebase-hooks/auth";

import './Auth.css';
 
const VerifyEmail = ({onClose}) => {
  const [message, setMessage] = useState('');
  const [countdown, setCountdown] = useState(0);
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    handleSendVerificationEmail();
  }, []);

  useEffect(() => {
    countdown > 0 && setTimeout(() => setCountdown(countdown - 1), 1000);
  }, [countdown]);


  const handleSendVerificationEmail = () => {
    if(countdown===0) {
      try {
        sendVerificationEmail(auth);
        setCountdown(30); //30 seconds
        console.log('email verification sent');
        
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
  
        if(errorCode === 'auth/too-many-requests') {
            setMessage('Email sent.');
  
        } else {
            setMessage('Something went wrong.');
        }
      }

    } else {
      console.log('email timeout not exceeded', countdown);
    }

  }

 
  return (
    <div className='auth-popup-container'>
        <h2>Verify Email</h2>

        <p>Verification email was sent to <b>{user!==null ? user.email : ''}</b>. Check your email for instructions to complete the sign up. </p>
        
        <p>Not seeing the verification email? Emails may take a few minutes to arrive and might be sent to your junk folder.</p>

        <p className='text-align-center'>
            Still not seeing it? <Button variant="link" onClick={handleSendVerificationEmail}
            disabled={countdown>0}
            >Send again{countdown>0?` (${countdown})`:''}</Button>
        </p>

        {message!=='' && (
            <p style={{color: 'red'}}>{message}</p>
        )}

        <Button variant="outline-light" onClick={onClose}>Close</Button>


    </div>
  )
}
 
export default VerifyEmail