import React, { useState } from 'react';
import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';
import Loader from '../../../../components/Loader/Loader';



function SubmissionConfigModal({submissionConfig, onHide}) {
    const [submissionState, setSubmissionState] = useState(submissionConfig.submissionState);
    const [loader, setLoader] = useState({show: false, text: ''});
    
    const submitNewState = (e, action) => {
        setLoader({show: true, text: 'Setting state...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
         
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              submissionId: submissionConfig.submissionId,
              state: submissionState
            })
        };
        
        fetch(apiUrl + `/app/submission/update`, requestOptions)
          .then(response => response.json())
          .then(() => {
            setSubmissionState();            
            setLoader({show: false});
            onHide(action);
        })
          .catch((err) => console.log(err));


    
        fetch(apiUrl + "/app/form/update", requestOptions)
        .then(response => response.json())
        .then(() => {
            setSubmissionState();
            onHide(action);
        })
        .catch((err) => console.log(err));
    }


    const handleCloseEvent = () => {
        setSubmissionState();
        onHide();
    }
    
    return (
        <Modal show={submissionConfig.show} onHide={handleCloseEvent} closeOnOverlayClick={true} className="form-config-modal">
            <Loader config={loader}></Loader>

            <h2>Submission Configuration</h2>
            <div className="label-adorner">
                {`{ submissionId: ${submissionConfig.submissionId}, state: ${submissionConfig.submissionState} }`}
            </div>

            <div className="divider"></div>

            <p><b>Select a new state for this form:</b></p>
            <div className="btn-group">
                {submissionConfig.submissionState === 'Draft' ? (
                <Button disabled>Draft<br />(Current)</Button>
                ) : (
                <Button variant={submissionState==='Draft' ? 'primary' : 'outline-light'} onClick={() => setSubmissionState('Draft')}>Draft</Button>
                )}

                {submissionConfig.submissionState === 'Submitted' ? (
                <Button disabled>Submitted<br />(Current)</Button>
                ) : (
                <Button variant={submissionState==='Submitted' ? 'primary' : 'outline-light'} onClick={() => setSubmissionState('Submitted')}>Submitted</Button>
                )}

            </div>
            
            {submissionState!==submissionConfig.submissionState && (
            <div>
                {submissionState==='Draft' && (
                <p>The submission will be set to Draft. The person who owns this submission will be able to make edits and submit.</p>
                )}

                {submissionState==='Submitted' && (
                <p>The submission will be set to Submitted. The person who owns this submission will NOT be able to make edits.</p>
                )}
            </div>
            )}
            
            <div className="divider"></div>

            <div className="modal-footer">
                <Button variant="secondary" onClick={handleCloseEvent}>Close</Button>
                <Button variant="primary" onClick={(e) => submitNewState(e, 'Update')}
                    disabled={submissionState===submissionConfig.submissionState}>Set</Button>
            </div>

        </Modal>

     );
    
}

export default SubmissionConfigModal;