import React, { useReducer, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';

import Button from '../../../atoms/Button/Button';
// import Dropdown from 'react-bootstrap/Dropdown';

import Loader from '../../../components/Loader/Loader';
import PictureChooser from '../../../components/PictureChooser/PictureChooser';

import SaveIcon from '@mui/icons-material/Save';
import './DriversTab.css';

emailjs.init('process.env.EMAIL_JS_USER_ID');

function reducer(state, action) {
    console.log(`reducer (${action.type}, ${action?.payload?.index})`);   
    
    let newState = structuredClone(state);
    try {

        let i = action?.payload?.index;

        switch(action.type) {
            case 'initialize':
                return action.payload;

            case 'add_driver':
                newState.push({
                    id: null,
                    firstName: '',
                    lastName: '',
                    dob: new Date(),
                    nationality: '',
                    code: '',
                    number: 0,
                    profilePicUrl: '',
                    active: true
                });
                return newState;

            case 'update_driver':
                if(action.payload.property === 'First Name') {
                    newState[i].firstName = action.payload.value;
                    
                } else if(action.payload.property === 'Last Name') {
                    newState[i].lastName = action.payload.value;
                    
                } else if(action.payload.property === 'DOB') {
                    newState[i].dob = action.payload.value;

                } else if(action.payload.property === 'Nationality') {
                    newState[i].nationality = action.payload.value;

                } else if(action.payload.property === 'Code') {
                    newState[i].code = action.payload.value;

                } else if(action.payload.property === 'Number') {
                    newState[i].number = action.payload.value;

                } else if(action.payload.property === 'Profile Pic URL') {
                    newState[i].profilePicUrl = action.payload.value;

                } else if(action.payload.property === 'Active') {
                    newState[i].active = action.payload.value;
                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                }
                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}



function DriverTab() {
    const [loader, setLoader] = useState({show: false, text: ''});
    const [pictureChooserConfig, setPictureChooserConfig] = useState({show: false});

    const [drivers, dispatch] = useReducer(reducer, []);
    const [fetchedData, setFetchedData] = useState(false);
    const [dbData, setDbData] = useState();


    useEffect(() => {
        console.log('useEffect DriversTab');
        if(!fetchedData) {
            fetchDrivers();
        }
    }, [drivers]);

    const fetchDrivers = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };

        setLoader({show: true, text: 'Loading drivers...'});
        fetch(apiUrl + `/app/ref/drivers`, requestOptions)
        .then(response => response.json())
        .then(data => {
            const ordered = data.sort((a, b) => a.lastName.localeCompare(b.lastName));

            const currentDate = new Date();
            const revisedData = ordered.map(d => { 
                const dob = new Date(d.dob); 
                const dobDate = dob.toISOString().split('T')[0]; // Extract only the date part 
                let age = currentDate.getFullYear() - dob.getFullYear(); 
                const month = currentDate.getMonth() - dob.getMonth(); 

                if (month < 0 || (month === 0 && currentDate.getDate() < dob.getDate())) {
                    age--; 
                } 
                
                return { ...d, dobDate, age }; 
            });
            setFetchedData(true);
            setDbData(revisedData);
            dispatch({type: 'initialize', payload: revisedData});
            setLoader({show: false});
        })
        .catch((err) => {
            console.log(err);            
            setLoader({show: false});
        });
    }

    const openPictureChooser = (index) => {
        setPictureChooserConfig({show: true, index: index});
    }

    const closePictureChooser = (url) => {
        console.log('url', url);
        let index = pictureChooserConfig.index;
        
        dispatch({type: 'update_driver', payload: {index, property: 'Profile Pic URL', value: url}});
        setPictureChooserConfig({show: false});
    }

    const handleDriverChange = (property, value, i) => {
        console.log(`handleDriverChange [${property}] => ${value} [${i}]`);
        
        dispatch({type: 'update_driver', payload: {index: i, property: property, value: value}});
    }
    
    const save = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                drivers: drivers
            })
        };

        setLoader({show: true, text: 'Saving drivers...'});
        fetch(apiUrl + "/app/ref/saveDrivers", requestOptions)
          .then(response => {
            if(response.status === 200) {
                setLoader({show: false});
                fetchDrivers();
            }
          })
        .catch((err) => {
            console.log(err);
            setLoader({show: false});
        });
    }

    const isDirty = () => {
        if (JSON.stringify(drivers) !== JSON.stringify(dbData)) { 
            return true; 
        } else {
            return false;
        }
    }

    return (
        <div>
            <Loader config={loader}></Loader>
            <PictureChooser config={pictureChooserConfig} onHide={closePictureChooser}></PictureChooser>

            <div className='flex justify-content-end' style={{marginInline: '1em'}}>
                <Button variant="primary" onClick={save} disabled={!isDirty()}>Save <SaveIcon fontSize='small' /></Button>
            </div>
            
            <div className="overflow-auto padding-1">
                <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th col-min-width="short-text">First Name</th>
                            <th col-min-width="short-text">Last Name</th>
                            <th col-min-width="long-text">DOB</th>
                            <th col-min-width="short-text">Nationality</th>
                            <th col-min-width="short-text">Code</th>
                            <th col-min-width="short-text">Number</th>
                            <th>Profile</th>
                            <th>Active</th>
                        </tr>
                    </thead>
                    <tbody>
                        { drivers.length > 0 && drivers.map((d, index) => (
                        <tr>
                            <td>{d.driverId}</td>
                            <td>
                                <input name="First Name" 
                                    type="fname" 
                                    value={d.firstName}
                                    onChange={(e) => handleDriverChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Last Name" 
                                    type="lname" 
                                    value={d.lastName}
                                    onChange={(e) => handleDriverChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <div className='flex flex-column'>
                                    <div>
                                        <input name="DOB" 
                                            type="date" 
                                            value={d.dobDate}
                                            onChange={(e) => handleDriverChange(e.target.name, e.target.value, index)} />
                                    </div>
                                    <div>(Age: {d.age})</div>
                                </div>
                            </td>
                            <td>
                                <input name="Nationality" 
                                    type="text" 
                                    value={d.nationality}
                                    onChange={(e) => handleDriverChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Code" 
                                    type="text" 
                                    value={d.code}
                                    onChange={(e) => handleDriverChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Number" 
                                    type="text" 
                                    value={d.number}
                                    onChange={(e) => handleDriverChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <Button variant="icon" onClick={() => openPictureChooser(index)}>
                                    <img className="answer-image small" src={d.profilePicUrl} alt="?" />
                                </Button>
                            </td>
                            <td>
                                <input name="Active" 
                                    type="checkbox" 
                                    checked={d.active}
                                    onChange={(e) => handleDriverChange(e.target.name, e.target.checked, index)} />
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default DriverTab;