import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate } from "react-router-dom";
import Button from '../../atoms/Button/Button';
import Modal from '../../atoms/Modal/Modal';
import UserChip from '../UserChip/UserChip';
import PlaceGlyph from '../../atoms/PlaceGlyph/PlaceGlyph';
import SettingsIcon from '@mui/icons-material/Settings';
import Loader from '../Loader/Loader';
import './PictureChooser.css';

function PictureChooser({config, onHide}) {
    const [loader, setLoader] = useState({show: false, text: ''});
    const [error, setError] = useState('');
    const [url, setUrl] = useState('');
    const [validUrl, setValidUrl] = useState('');
  
    // useEffect(() => {
    //     if(config.show) {
    //         console.log('config', config);
    //     }

    // }, [config]);
  
    useEffect(() => {
        isUrlValid();
    }, [url]);
    
    const isUrlValid = async () => {

        if(url.length === 0) {
            setError('');
            setValidUrl('');
            return Promise.resolve(false);

        } else if(url.length > 500) {
            setValidUrl('');
            setError('URL must be less than 500 characters');
            return Promise.resolve(false);

        } else if(!url.startsWith('http')) {
            setValidUrl('');
            setError('URL should start with http');
            return Promise.resolve(false);

        } else if(!await isUrlAnImage(url)) {
            setValidUrl('');
            setError('URL is not valid');
            return Promise.resolve(false);

        } else {
            setValidUrl(url);
            setError('');
            return Promise.resolve(true);
        }
    }
    const isUrlAnImage = (url) => { 
        return new Promise((resolve) => { 
            const img = new Image(); 
            img.onload = () => resolve(true); 
            img.onerror = () => resolve(false); 
            img.src = url; 

        }); 
    }

    const handleCloseEvent = () => {
        onHide(validUrl);
        setUrl('');
        setValidUrl('');
    }


    const isDirty = () => {
        if(validUrl !== '') {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div>
            <Loader config={loader}></Loader>
      
            <Modal show={config.show} onHide={handleCloseEvent} closeOnOverlayClick={true}>
                <h2>Choose Picture</h2>
                <div className='flex flex-column gap-05'>
                    <div className='flex justify-content-between'>
                        <div style={{color: 'red'}}>{error}</div>
                        <Button variant="primary" onClick={handleCloseEvent} disabled={!isDirty()}>Save</Button>
                    </div>
                    <div className='flex flex-column align-items-center gap-1'>
                        <textarea value={url} placeholder='Enter URL...' onChange={(e) => setUrl(e.target.value)} rows='4' />
                        <img className="img-preview" src={validUrl} alt="Preview" />
                    </div>
                </div>

                <div className="modal-footer">
                    <Button variant="secondary" onClick={handleCloseEvent}>Cancel</Button>
                </div>
            </Modal>
        </div>
    )
}

export default PictureChooser;