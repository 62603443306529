import React, { useReducer, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';

import Button from '../../../atoms/Button/Button';
// import Dropdown from 'react-bootstrap/Dropdown';

import Loader from '../../../components/Loader/Loader';
import PictureChooser from '../../../components/PictureChooser/PictureChooser';

import SaveIcon from '@mui/icons-material/Save';
import './ConstructorsTab.css';
import { CoPresentSharp } from '@mui/icons-material';


function reducer(state, action) {
    console.log(`reducer (${action.type}, ${action?.payload?.index})`);   
    
    let newState = structuredClone(state);
    try {

        let i = action?.payload?.index;

        switch(action.type) {
            case 'initialize':
                return action.payload;

            case 'add_constructor':
                newState.push({
                    id: null,
                    name: '',
                    nationality: '',
                    logoUrl: '',
                    accentColor: '',
                    carPicUrl: '',
                    active: true
                });
                return newState;

            case 'update_constructor':
                if(action.payload.property === 'Name') {
                    newState[i].name = action.payload.value;
                    
                } else if(action.payload.property === 'Nationality') {
                    newState[i].nationality = action.payload.value;
                    
                } else if(action.payload.property === 'Logo URL') {
                    newState[i].logoUrl = action.payload.value;

                } else if(action.payload.property === 'Accent Color') {
                    newState[i].accentColor = action.payload.value;

                } else if(action.payload.property === 'Car Pic URL') {
                    newState[i].carPicUrl = action.payload.value;

                } else if(action.payload.property === 'Active') {
                    newState[i].active = action.payload.value;
                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                }
                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}



function ConstructorsTab() {
    const [loader, setLoader] = useState({show: false, text: ''});
    const [pictureChooserConfig, setPictureChooserConfig] = useState({show: false});

    const [constructors, dispatch] = useReducer(reducer, []);
    const [fetchedData, setFetchedData] = useState(false);
    const [dbData, setDbData] = useState();


    useEffect(() => {
        console.log('useEffect ConstructorsTab');
        if(!fetchedData) {
            fetchConstructors();
        }
    }, [constructors]);

    const fetchConstructors = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };

        setLoader({show: true, text: 'Loading constructors...'});
        fetch(apiUrl + `/app/ref/constructors`, requestOptions)
        .then(response => response.json())
        .then(data => {
            const ordered = data.sort((a, b) => a.name.localeCompare(b.name));

            setFetchedData(true);
            setDbData(ordered);
            dispatch({type: 'initialize', payload: ordered});
            setLoader({show: false});            
        })
        .catch((err) => {
            console.log(err);            
            setLoader({show: false});
        });
    }

    const openPictureChooser = (index, property) => {
        setPictureChooserConfig({show: true, index, property});
    }

    const closePictureChooser = (url) => {
        console.log('url', url);
        let index = pictureChooserConfig.index;
        let property = pictureChooserConfig.property;
        
        dispatch({type: 'update_constructor', payload: {index, property, value: url}});
        setPictureChooserConfig({show: false});
    }

    const handleConstructorChange = (property, value, i) => {
        console.log(`handleConstructorChange [${property}] => ${value} [${i}]`);
        
        dispatch({type: 'update_constructor', payload: {index: i, property: property, value: value}});
    }
    
    const save = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                constructors: constructors
            })
        };

        setLoader({show: true, text: 'Saving constructors...'});
        fetch(apiUrl + "/app/ref/saveConstructors", requestOptions)
          .then(response => {
            if(response.status === 200) {
                setLoader({show: false});
                fetchConstructors();
            }
          })
        .catch((err) => {
            console.log(err);
            setLoader({show: false});
        });
    }

    const isDirty = () => {
        if (JSON.stringify(constructors) !== JSON.stringify(dbData)) { 
            return true; 
        } else {
            return false;
        }
    }

    return (
        <div>
            <Loader config={loader}></Loader>
            <PictureChooser config={pictureChooserConfig} onHide={closePictureChooser}></PictureChooser>

            <div className='flex justify-content-end' style={{marginInline: '1em'}}>
                <Button variant="primary" onClick={save} disabled={!isDirty()}>Save <SaveIcon fontSize='small' /></Button>
            </div>
            
            <div className="overflow-auto padding-1">
                <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th col-min-width="long-text">Name</th>
                            <th>Nationality</th>
                            <th>Logo</th>
                            <th>Color</th>
                            <th>Car</th>
                            <th>Active</th>
                        </tr>
                    </thead>
                    <tbody>
                        { constructors.length > 0 && constructors.map((c, index) => (
                        <tr>
                            <td>{c.constructorId}</td>
                            <td>
                                <input name="Name" 
                                    type="text" 
                                    value={c.name}
                                    onChange={(e) => handleConstructorChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Nationality" 
                                    type="text" 
                                    value={c.nationality}
                                    onChange={(e) => handleConstructorChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <Button variant="icon" onClick={() => openPictureChooser(index, 'Logo URL')}>
                                    <img className="answer-image small" src={c.logoUrl} alt="?" />
                                </Button>                                
                            </td>
                            <td>
                                <input name="Accent Color" 
                                    type="color" 
                                    value={c.accentColor || '#000000'}
                                    onChange={(e) => handleConstructorChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <Button variant="icon" onClick={() => openPictureChooser(index, 'Car Pic URL')}>
                                    <img className="answer-image small" src={c.carPicUrl} alt="?" />
                                </Button>                                
                            </td>
                            <td>
                                <input name="Active" 
                                    type="checkbox" 
                                    checked={c.active}
                                    onChange={(e) => handleConstructorChange(e.target.name, e.target.checked, index)} />
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ConstructorsTab;