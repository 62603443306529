import React, { useState, useEffect } from 'react';
import { auth, addNewUser } from '../../firebase';
import Button from '../../atoms/Button/Button';

import './Auth.css';
 
const EmailVerified = ({onClose}) => {

  
  // useEffect(() => {
  //   addNewUser(auth);
  // }, []);
 
  return (
    <div className='auth-popup-container'>
        <h2>Email Sign Up</h2>

        <p>Email verified! Continue to complete your profile.</p>
                
        <Button variant="primary" onClick={onClose}>Continue</Button>


    </div>
  )
}
 
export default EmailVerified