import React, { useReducer, useState, useEffect } from 'react';

import Button from '../../../atoms/Button/Button';
// import Dropdown from 'react-bootstrap/Dropdown';

import Loader from '../../../components/Loader/Loader';
import PictureChooser from '../../../components/PictureChooser/PictureChooser';

import SaveIcon from '@mui/icons-material/Save';
import './EventsTab.css';
import { SignalCellularNullOutlined } from '@mui/icons-material';


function reducer(state, action) {
    console.log(`reducer (${action.type}, ${action?.payload?.index})`);   
    
    let newState = structuredClone(state);
    try {

        let i = action?.payload?.index;

        switch(action.type) {
            case 'initialize':
                return action.payload;

            case 'add_event':
                newState.push({
                    id: null,
                    name: '',
                    type: '',
                    date: new Date(),
                    circuitId: null,
                    rount: '',
                    season: SignalCellularNullOutlined,
                    url: '',
                    posterUrl: '',
                    accentColor: '',
                    meetingKey: null,
                    sessionKey: null,
                });
                return newState;

            case 'update_event':
                if(action.payload.property === 'Name') {
                    newState[i].name = action.payload.value;
                    
                } else if(action.payload.property === 'Type') {
                    newState[i].type = action.payload.value;
                    
                } else if(action.payload.property === 'Date') {
                    newState[i].date = action.payload.value;

                } else if(action.payload.property === 'Ciruit ID') {
                    newState[i].circuitId = action.payload.value;

                } else if(action.payload.property === 'Round') {
                    newState[i].round = action.payload.value;

                } else if(action.payload.property === 'Season') {
                    newState[i].season = action.payload.value;

                } else if(action.payload.property === 'URL') {
                    newState[i].url = action.payload.value;

                } else if(action.payload.property === 'Poster URL') {
                    newState[i].posterUrl = action.payload.value;

                } else if(action.payload.property === 'Accent Color') {
                    newState[i].accentColor = action.payload.value;

                } else if(action.payload.property === 'Meeting Key') {
                    newState[i].meetingKey = action.payload.value;

                } else if(action.payload.property === 'Session Key') {
                    newState[i].sessionKey = action.payload.value;

                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                }
                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}



function EventsTab() {
    const [loader, setLoader] = useState({show: false, text: ''});
    const [pictureChooserConfig, setPictureChooserConfig] = useState({show: false});

    const [events, dispatch] = useReducer(reducer, []);
    const [fetchedData, setFetchedData] = useState(false);
    const [dbData, setDbData] = useState();


    useEffect(() => {
        console.log('useEffect EventsTab');
        if(!fetchedData) {
            fetchEvents();
        }
    }, [events]);

    const fetchEvents = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };

        setLoader({show: true, text: 'Loading events...'});
        fetch(apiUrl + `/app/ref/events`, requestOptions)
        .then(response => response.json())
        .then(data => {
            const ordered = data.sort((a, b) => { if (b.season === a.season) { return b.round - a.round; } return b.season - a.season; });

            setFetchedData(true);
            setDbData(ordered);
            dispatch({type: 'initialize', payload: ordered});
            setLoader({show: false});            
        })
        .catch((err) => {
            console.log(err);            
            setLoader({show: false});
        });
    }

    const openPictureChooser = (index, property) => {
        setPictureChooserConfig({show: true, index, property});
    }

    const closePictureChooser = (url) => {
        console.log('url', url);
        let index = pictureChooserConfig.index;
        let property = pictureChooserConfig.property;
        
        dispatch({type: 'update_event', payload: {index, property, value: url}});
        setPictureChooserConfig({show: false});
    }

    const handleEventChange = (property, value, i) => {
        console.log(`handleEventChange [${property}] => ${value} [${i}]`);
        
        dispatch({type: 'update_event', payload: {index: i, property: property, value: value}});
    }
    
    const save = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                events: events
            })
        };

        setLoader({show: true, text: 'Saving events...'});
        fetch(apiUrl + "/app/ref/saveEvents", requestOptions)
          .then(response => {
            if(response.status === 200) {
                setLoader({show: false});
                fetchEvents();
            }
          })
        .catch((err) => {
            console.log(err);
            setLoader({show: false});
        });
    }

    const isDirty = () => {
        if (JSON.stringify(events) !== JSON.stringify(dbData)) { 
            return true; 
        } else {
            return false;
        }
    }

    return (
        <div>
            <Loader config={loader}></Loader>
            <PictureChooser config={pictureChooserConfig} onHide={closePictureChooser}></PictureChooser>

            <div className='flex justify-content-end' style={{marginInline: '1em'}}>
                <Button variant="primary" onClick={save} disabled={!isDirty()}>Save <SaveIcon fontSize='small' /></Button>
            </div>

            <div className="overflow-auto padding-1">
                <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th col-min-width="short-text">Season</th>
                            <th col-min-width="short-text">Round</th>
                            <th col-min-width="long-text">Name</th>
                            <th col-min-width="short-text">Type</th>
                            <th col-min-width="short-text">Date</th>
                            <th col-min-width="short-text">Circuit</th>
                            <th col-min-width="short-text">URL</th>
                            <th col-min-width="short-text">Poster</th>
                            <th>Accent Color</th>
                            <th col-min-width="short-text">Meeting Key</th>
                            <th col-min-width="short-text">Session Key</th>
                        </tr>
                    </thead>
                    <tbody>
                        { events.length > 0 && events.map((e, index) => (
                        <tr>
                            <td>{e.eventId}</td>
                            <td>
                                <input name="Season" 
                                    type="text" 
                                    value={e.season}
                                    onChange={(e) => handleEventChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Round" 
                                    type="text" 
                                    value={e.round}
                                    onChange={(e) => handleEventChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Name" 
                                    type="text" 
                                    value={e.name}
                                    onChange={(e) => handleEventChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Type" 
                                    type="text" 
                                    value={e.type}
                                    onChange={(e) => handleEventChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Date" 
                                    type="text" 
                                    value={e.date}
                                    onChange={(e) => handleEventChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Circuit ID" 
                                    type="text" 
                                    value={e.circuitId}
                                    onChange={(e) => handleEventChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="URL" 
                                    type="text" 
                                    value={e.url}
                                    onChange={(e) => handleEventChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <Button variant="icon" onClick={() => openPictureChooser(index, 'Poster URL')}>
                                    <img className="poster-thumbnail" src={e.posterUrl} alt="?" />
                                </Button>
                            </td>
                            <td>
                                <input name="Accent Color" 
                                    type="color" 
                                    value={e.accentColor || '#000000'}
                                    onChange={(e) => handleEventChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Meeting Key" 
                                    type="text" 
                                    value={e.meetingKey}
                                    onChange={(e) => handleEventChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Session Key" 
                                    type="text" 
                                    value={e.sessionKey}
                                    onChange={(e) => handleEventChange(e.target.name, e.target.value, index)} />
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default EventsTab;