import React, { useReducer, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';

import Button from '../../../atoms/Button/Button';
// import Dropdown from 'react-bootstrap/Dropdown';

import Loader from '../../../components/Loader/Loader';
import PictureChooser from '../../../components/PictureChooser/PictureChooser';

import SaveIcon from '@mui/icons-material/Save';
import './CircuitsTab.css';


function reducer(state, action) {
    console.log(`reducer (${action.type}, ${action?.payload?.index})`);   
    
    let newState = structuredClone(state);
    try {

        let i = action?.payload?.index;

        switch(action.type) {
            case 'initialize':
                return action.payload;

            case 'add_circuit':
                newState.push({
                    id: null,
                    name: '',
                    locality: '',
                    country: '',
                    lat: '',
                    long: '',
                    wikiUrl: '',
                    trackUrl: '',
                    trackSvgUrl: '',
                });
                return newState;

            case 'update_circuit':
                if(action.payload.property === 'Name') {
                    newState[i].name = action.payload.value;
                    
                } else if(action.payload.property === 'Locality') {
                    newState[i].locality = action.payload.value;
                    
                } else if(action.payload.property === 'Country') {
                    newState[i].country = action.payload.value;

                } else if(action.payload.property === 'Lat') {
                    newState[i].lat = action.payload.value;

                } else if(action.payload.property === 'Wiki URL') {
                    newState[i].wikiUrl = action.payload.value;

                } else if(action.payload.property === 'Track URL') {
                    newState[i].trackUrl = action.payload.value;

                } else if(action.payload.property === 'Track SVG URL') {
                    newState[i].trackSvgUrl = action.payload.value;

                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                }
                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}



function CircuitsTab() {
    const [loader, setLoader] = useState({show: false, text: ''});
    const [pictureChooserConfig, setPictureChooserConfig] = useState({show: false});

    const [circuits, dispatch] = useReducer(reducer, []);
    const [fetchedData, setFetchedData] = useState(false);
    const [dbData, setDbData] = useState();


    useEffect(() => {
        console.log('useEffect CircuitsTab');
        if(!fetchedData) {
            fetchCircuits();
        }
    }, [circuits]);

    const fetchCircuits = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };

        setLoader({show: true, text: 'Loading circuits...'});
        fetch(apiUrl + `/app/ref/circuits`, requestOptions)
        .then(response => response.json())
        .then(data => {
            const ordered = data.sort((a, b) => a.name.localeCompare(b.name));

            setFetchedData(true);
            setDbData(ordered);
            dispatch({type: 'initialize', payload: ordered});
            setLoader({show: false});            
        })
        .catch((err) => {
            console.log(err);            
            setLoader({show: false});
        });
    }

    const openPictureChooser = (index, property) => {
        setPictureChooserConfig({show: true, index, property});
    }

    const closePictureChooser = (url) => {
        console.log('url', url);
        let index = pictureChooserConfig.index;
        let property = pictureChooserConfig.property;
        
        dispatch({type: 'update_circuit', payload: {index, property, value: url}});
        setPictureChooserConfig({show: false});
    }

    const handleCircuitChange = (property, value, i) => {
        console.log(`handleCircuitChange [${property}] => ${value} [${i}]`);
        
        dispatch({type: 'update_circuit', payload: {index: i, property: property, value: value}});
    }
    
    const save = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                circuits: circuits
            })
        };

        setLoader({show: true, text: 'Saving circuits...'});
        fetch(apiUrl + "/app/ref/saveCircuits", requestOptions)
          .then(response => {
            if(response.status === 200) {
                setLoader({show: false});
                fetchCircuits();
            }
          })
        .catch((err) => {
            console.log(err);
            setLoader({show: false});
        });
    }

    const isDirty = () => {
        if (JSON.stringify(circuits) !== JSON.stringify(dbData)) { 
            return true; 
        } else {
            return false;
        }
    }

    return (
        <div>
            <Loader config={loader}></Loader>
            <PictureChooser config={pictureChooserConfig} onHide={closePictureChooser}></PictureChooser>

            <div className='flex justify-content-end' style={{marginInline: '1em'}}>
                <Button variant="primary" onClick={save} disabled={!isDirty()}>Save <SaveIcon fontSize='small' /></Button>
            </div>

            <div className="overflow-auto padding-1">
                <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th col-min-width="long-text">Name</th>
                            <th col-min-width="long-text">Locality</th>
                            <th col-min-width="short-text">Country</th>
                            <th col-min-width="short-text">Lat</th>
                            <th col-min-width="short-text">Long</th>
                            <th col-min-width="short-text">Wiki</th>
                            <th>Track</th>
                            <th>Track SVG</th>
                        </tr>
                    </thead>
                    <tbody>
                        { circuits.length > 0 && circuits.map((c, index) => (
                        <tr>
                            <td>{c.circuitId}</td>
                            <td>
                                <input name="Name" 
                                    type="text" 
                                    value={c.name}
                                    onChange={(e) => handleCircuitChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Locality" 
                                    type="text" 
                                    value={c.locality}
                                    onChange={(e) => handleCircuitChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Country" 
                                    type="text" 
                                    value={c.country}
                                    onChange={(e) => handleCircuitChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Lat" 
                                    type="text" 
                                    value={c.lat}
                                    onChange={(e) => handleCircuitChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Long" 
                                    type="text" 
                                    value={c.long}
                                    onChange={(e) => handleCircuitChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Wiki URL" 
                                    type="text" 
                                    value={c.wikiUrl}
                                    onChange={(e) => handleCircuitChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <Button variant="icon" onClick={() => openPictureChooser(index, 'Track URL')}>
                                    <img className="circuit-map small" src={c.trackUrl} alt="?" />
                                </Button>
                            </td>
                            <td>
                                <Button variant="icon" onClick={() => openPictureChooser(index, 'Track SVG URL')}>
                                    <img className="circuit-map small" src={c.trackSvgUrl} alt="?" />
                                </Button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CircuitsTab;