import React, { useState, useEffect } from 'react';

import Button from '../../../atoms/Button/Button';
import Modal from '../../../atoms/Modal/Modal';

import Loader from '../../../components/Loader/Loader';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import './EventSettings.css';

function EventSettings({config, onHide}) {
    const [message, setMessage] = useState('');
    const [loader, setLoader] = useState({show: false, text: ''});
    
    useEffect(() => {
        setMessage('');

        // setMeetingKey(config.meetingKey);
        // setSessionKey(config.sessionKey);
        if(config.show) {
            // fetchEvents();
            console.log('config', config);
        }

    }, [config]);

    


    const handleCloseEvent = () => {
        onHide();
    }
    
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Modal show={config.show} onHide={handleCloseEvent} closeOnOverlayClick={true}>
                <Loader config={loader}></Loader>
                <h2>Event Settings</h2>
                <div className="label-adorner">
                {`{ eventId: ${config.eventId} }`}
                </div>

                <div className="divider"></div>

                <div>{message}</div>



                <div className="modal-footer">
                    <Button variant="outline-light" onClick={handleCloseEvent}>Close</Button>
                </div>

            </Modal>    
        </LocalizationProvider>

     );
    
}

export default EventSettings;