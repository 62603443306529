import React from 'react';
import Button from '../../atoms/Button/Button';
import Modal from '../../atoms/Modal/Modal';


function AreYouSureModal({config, onHide}) {
    const handleConfirm = () => {
        onHide('Confirm');
    }
    const handleCancel = () => {
        onHide('Cancel');
    }
    
    return (
        <Modal show={config.show} onHide={onHide} closeOnOverlayClick={true}>
            <h2>{config.header === undefined ? `Are you sure` : config.header}</h2>
            
            {config.body !== undefined &&(
            <p>{config.body}</p>
            )}

            <div className="modal-footer">
                <Button variant="secondary" onClick={handleCancel}>{config.cancelText === undefined ? 'Cancel' : config.cancelText}</Button>
                <Button variant="warn" onClick={handleConfirm}>{config.confirmText === undefined ? 'Yes' : config.confirmText}</Button>
            </div>
      </Modal>

     );
    
}

export default AreYouSureModal;